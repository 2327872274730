import { format } from 'date-fns'

export class Comentario {
    public idAnotacaoApi?:string;
    public texto = '';
    public data = new Date();
    public idUsuarioPassaporte?:number;
    public nomeUsuarioPassaporte?:string;
  
    constructor(c?: Partial<Comentario>) {
        Object.assign(this, c);
    }

    public isValid() {
      return !!(this.texto) && !!(this.texto.trim().length);
    }

    public dataFormatada() {
        return format(new Date(this.data), "dd/MM/yyyy 'às' HH:mm");
    }

  }