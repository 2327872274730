import axios from 'axios'
import _ from 'lodash';
import { Anexo } from '../models/anexo_model'
import { MS_PESSOA_BASE_API } from '@/constants'
import customEvents from "../services/custom_events";


export default {

    async processarAnexo(anexos:Anexo[], idPessoa:string, idUsuarioExterno:string) {

        if (anexos.length === 0 || !this._temAnexosNovos(anexos)) {
          return Promise.resolve([anexos]);
        }

        const _anexosNovos = this._filtraAnexosNovos(anexos);

        const anexosConfigs = await this._gerarUrlAnexo(_anexosNovos, idPessoa);
        const anexosFinal = await this._publicarAnexo(anexosConfigs, _anexosNovos);
        return await this._gravarAnexoPessoa(anexosFinal, idPessoa, idUsuarioExterno);
    },

    async removerAnexosPessoa(listaIdsExclusao: string[]) {
        if (listaIdsExclusao.length === 0) {
            return Promise.resolve(listaIdsExclusao);
        }

        const idsExclusao = {
            headers: {
            'Content-Type': 'application/json'
            },
            data: listaIdsExclusao
        };
        const { data } = await axios.delete(MS_PESSOA_BASE_API + 'anexos', idsExclusao);
        return data;
    },

    _gerarUrlAnexo(anexos:Anexo[], id:string) {
        const _url = MS_PESSOA_BASE_API + `${id}/anexos/url`;

        const promisses:any = [];

        anexos.map((anexo) => {
            if (anexo.arquivo) {
                const formData = new FormData();
    
                formData.append('arquivo', anexo.arquivo.file);
    
                promisses.push(axios({
                    url: _url,
                    method:'POST',
                    data: formData,
                    headers: {
                        "Content-Type": 'multipart/form-data'
                    }
                }));
            }
        });

        customEvents.exibeCarregando();

        return axios.all(promisses)
            .then((result) => {
                const tmp:any = [];
                _.forEach(result, (response:any) => tmp.push(response.data));
                return tmp;
            })
            .then((tmpResult) => tmpResult)
            .finally(() => {
                customEvents.escondeCarregando();
            });
    },

    _publicarAnexo(anexosConfigs:any, anexos:Anexo[]) {

        const promisses:any = [];
        const anexosConfigsComIdUsuario:any = [];
        customEvents.exibeCarregando();

        anexosConfigs.map((c:any, indice:number) => {
            const _config = {
                headers: {
                    "Content-Type": c.contentType
                }
            };

            //c.idUsuarioPassaporte = anexos[indice].idUsuarioPassaporte;
            anexosConfigsComIdUsuario.push(c);
            promisses.push(axios.put(c.urlUpload, anexos[indice].arquivo.file, _config));
        });

        return axios.all(promisses)
            .then(() => anexosConfigsComIdUsuario)
            .finally(() => {
                customEvents.escondeCarregando();
            });
    },

    _gravarAnexoPessoa(anexos:Anexo[], idPessoa:string, idUsuarioExterno:string) {
        return axios.all(anexos.map((c:any) => {
            const dadosForm = new FormData();
            dadosForm.append('id_Pessoa', c.idPessoa);
            dadosForm.append('urlUpload', c.urlUpload);
            dadosForm.append('hash', c.hash);
            dadosForm.append('nomeArquivo', c.nomeArquivo);
            dadosForm.append('idUsuarioExterno', idUsuarioExterno);
            dadosForm.append('contentType', c.contentType);

            customEvents.exibeCarregando();

            axios({
                url: MS_PESSOA_BASE_API + `${idPessoa}/anexos`,
                method:'POST',
                data: dadosForm,
                headers: {
                    "Content-Type": 'multipart/form-data'
                }
            })
            .then(() => {
                _.forEach(anexos, (an:Anexo, indiceAnexos:number) => {
                    if (an.nome === c.nome) {
                        anexos[indiceAnexos] = c;
                    }
                });
            })
            .finally(() => {
                customEvents.escondeCarregando();
            });
        }));
    },

    _temAnexosNovos(anexos:Anexo[]) {
        for (let i = 0, _tamanho = anexos.length; i < _tamanho; i++) {
            if (!anexos[i].idAnexo) {
                return true;
            }
        }
    },

    _filtraAnexosNovos(anexos:Anexo[]) {
        return _.filter(anexos, (anexo:Anexo) => {
            return !anexo.idAnexo;
        });
    }

}