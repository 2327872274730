
<script lang="ts">
import enderecoStore from '@/stores/endereco_store';
import { Endereco } from '@/models/endereco_model';
import ItemListaEnderecos from '@/components/endereco/ItemListaEnderecos.vue';
import UndoToastComponent, { UndoToastComponentRef } from '@/libs/components/undo-toast/index';

export default {
  name: 'ListaEnderecos',
  components: {
    ItemListaEnderecos,
    UndoToastComponent
  },
  computed: {
    enderecos() {
      return enderecoStore.state.enderecos
    },
    removidos() {
      return enderecoStore.state.removidos
    },
    undoToastComponentRef(): UndoToastComponentRef {
      return this.$refs.UndoToastComponentRef as UndoToastComponentRef;
    }
  },
  methods: {
    editarEndereco(endereco: Endereco, index: number) {
      this.$emit('editarEndereco', endereco, index);
    },
    excluirEndereco(endereco: Endereco, index: number) {
      this.$emit('excluirEndereco', endereco, index);
      this.showUndoToast();
    },
    showUndoToast() {
      const qtdRemovidos = this.removidos.length;
      const mensagem =
        qtdRemovidos > 1
          ? `${qtdRemovidos} endereços removidos`
          : 'Endereço removido da lista';

      this.undoToastComponentRef.show(mensagem);
    },
    limparRemovidos() {
      enderecoStore.dispatch('limparRemovidos');
    },
    desfazerRemovidos() {
      this.enderecos.push(...this.removidos);   
      enderecoStore.dispatch('ordenar');
    }
  }
}
</script>

<template>
  <div id="erp4me-mf-pessoa-lista-enderecos">
    <div class="my-5" v-for="(endereco, key) in enderecos" :key="key">
      <ItemListaEnderecos
        :index="key"
        :endereco="endereco"
        :startExpanded="endereco.principal || enderecos.length === 1"
        @editarEndereco="editarEndereco"
        @excluirEndereco="excluirEndereco"/>
    </div>
    <UndoToastComponent
      ref="UndoToastComponentRef"
      @onUndoClick="desfazerRemovidos"
      @onToastClose="limparRemovidos" />
  </div>
</template>