import { PassaporteUsuarioLogado } from '../models/passaporte_usuario_logado_model';

class PassaporteUsuarioLogadoManager {
    retorna() {
        return new PassaporteUsuarioLogado(JSON.parse(localStorage.pass_usuario_auth));
    }
}

const passaporteUsuarioLogadoManagerSingleton = new PassaporteUsuarioLogadoManager();

Object.freeze(passaporteUsuarioLogadoManagerSingleton);

export default passaporteUsuarioLogadoManagerSingleton;