import empresaManager from '../../empresa/managers/empresa_manager';
import { EmpresaProdutos } from '../../empresa/models/empresa_produtos_model';

class PassaporteProdutosAcessos {
  public produtos:Array<EmpresaProdutos> = [];

  constructor() {
    this.produtos = empresaManager.getEmpresaEscolhidaFromStorage().produtos;
  }

  temAcessoFuncionalidade(funcionalidade:string) {
    if (this.produtos) {
      for (let indiceProd = this.produtos.length - 1; indiceProd >= 0; indiceProd--) {
        const funcionalidades = this.produtos[indiceProd].funcionalidades;
        if (!funcionalidades) {
          continue;
        }
  
        for (let indiceFuncionalidade = 0, len = funcionalidades.length; indiceFuncionalidade < len; indiceFuncionalidade++) {
          if (funcionalidades[indiceFuncionalidade].idExterno === funcionalidade) {
            return true;
          }
        }
      }
    }

    return false;
  }

  temAcessoProduto(chaveProduto:string) {
    if (this.produtos) {
      for (let i = 0; i < this.produtos.length; i++) {
        const produto = this.produtos[i];
        if (produto.chaveProduto === chaveProduto && !produto.isCancelado) {
          return true;
        }
        
        for (let j = 0; j < this.produtos[i].modulos.length; j++) {
          const modulo = this.produtos[i].modulos[j];
          if (modulo.chaveProduto === chaveProduto && !modulo.isCancelado) {
            return true;
          }
        }
      }
    }

    return false;
  }
}

export default PassaporteProdutosAcessos;