
import { Base } from '../libs/comum/models/base_model';

export class Veiculo extends Base {
    public idVeiculo:number|undefined = undefined;
    public placa = '';
    public uf: {sigla: '', nome: ''}|undefined = undefined;
    public antt = '';
    public placaRepetida:boolean|undefined = undefined;

    constructor(v?: Partial<Veiculo>) {
        super();
        Object.assign(this, v);
    }

    isNew() {
        return !this.idVeiculo;
    }

    temPlacaValida() {
        this.placa = this.placa.toUpperCase();
        const regexPlacas = /(^[a-zA-Z]{3}[0-9]{3,4}$)|(^[a-zA-Z]{2}[0-9]{4}$)|(^[a-zA-Z]{4}[0-9]{3}$)|(^[a-zA-Z]{3}[0-9][a-zA-Z][0-9]{2}$)/gm;
        return regexPlacas.test(this.placa);
    }

    verificaPendenciaPlacaInvalida() {
        this.removerPendencia('placa')
        if (!this.temPlacaValida()) {
          this.adicionarPendencia({
            prop: 'placa',
            mensagem: 'Informe uma <b>Placa</b> válida.'
          });
          return true;
        }
        return false;
    }

    verificaRepeticaoPlaca(veiculos:Veiculo[]) {
        const _placas = Object.create(null);
        for (let i = 0; i < veiculos.length; ++i) {
            const placa = veiculos[i].placa;
            if (placa in _placas) {
                veiculos[i].placaRepetida = true;
            }
            else {
                veiculos[i].placaRepetida = undefined;
            }
            if (placa) {
                _placas[placa] = true;
            }
        }
    }
}