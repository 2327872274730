<template>
  <div id="app">
    <notivuecation-component />
    <kooponPessoaPessoaEspecifica />
  </div>
</template>

<script lang="ts">
// Estilos dos Framworks
import { Component, Vue } from 'vue-property-decorator';
import kooponPessoaPessoaEspecifica from './components/pessoa_especifica.vue';
import NotivuecationComponent from '@/libs/dialogs/index';
import Multiselect from 'vue-multiselect'
import VCalendar from 'v-calendar'
import VueMask from 'v-mask'

Vue.use(VCalendar)
Vue.use(VueMask);
Vue.use(NotivuecationComponent);

Vue.component('vue-multiselect', Multiselect)

@Component({
  components: {
    kooponPessoaPessoaEspecifica,
  }
})
export default class App extends Vue {
}
</script>

<style lang="scss">
@import "./main.scss";
</style>
