<template>
    <sd-tour
        :steps.prop="steps" 
        :startTourKey.prop="startTourKey"
        @onFinish="onFinish"
        @onSkip="onSkip"
        @onNextStep="onNextStep"
        @onPreviousStep="onPreviousStep"/>
</template>

<script>

import { ubus } from '@/constants';
import tourService from '@/services/tour_service'

export default {
    name: "TourVirtual",
    props: {
        steps: {
            type: Array,
            required: true,
            default: () => { return []}
        },
        codigoFuncionalidade: {
            type: Number
        }
    },
    data() {
        return {
            startTourKey: 0
        }
    },
    methods: {
        exibirTourNovamente() {
            this.startTourKey++;
        },
        async verificaExibicaoTour() {
            const tourFinalizado = await tourService.obterPorCodigo(this.codigoFuncionalidade);

            if (!tourFinalizado) this.exibirTourNovamente();
        },
        onPreviousStep(ev) {
            let currentStep = this._getEventDetail(ev);
            this._removeMock(currentStep);
            this._adicionaMock(this.steps[currentStep - 1])
            this.$emit('onPreviousStep', this._getEventDetail(ev));
        },
        onNextStep(ev) {
            let currentStep = this._getEventDetail(ev); 
            this._removeMock(currentStep);
            this._adicionaMock(this.steps[currentStep + 1])
            this.$emit('onNextStep', this._getEventDetail(ev));
        },
        async onFinish(ev) {
            await tourService.setarTourFinalizado(this.codigoFuncionalidade);

            let currentStep = this._getEventDetail(ev);

            this._removeMock(currentStep);
            this.$emit('onFinish');
        },
        async onSkip(ev) {
            await tourService.setarTourFinalizado(this.codigoFuncionalidade);

            let currentStep = this._getEventDetail(ev);

            this._removeMock(currentStep);
            this.$emit('onSkip', this._getEventDetail(ev));
        },
        _adicionaMock(step) {
            if (step && step.evento && step.evento.adiciona) {
                ubus.emit(step.evento.adiciona.nome, step.evento.adiciona.dados)
            }
        },
        _removeMock(currentStep) {
            let step = this.steps[currentStep]
            if (step && step.evento && step.evento.remove) {
                ubus.emit(step.evento.remove.nome)
            }
        },
        _getEventDetail(ev) {
            return ev.detail[0];
        }
    }
}
</script>

<style lang="scss">

    $shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

    .sd-tour {
        pointer-events: auto;

        &__target {
            &--highlighted {
                background: #fff;
                -webkit-box-shadow: $shadow;
                box-shadow: $shadow;
                pointer-events: auto;
                z-index: 111;

                &:after {
                    content: '';
                    position: absolute;
                    z-index: 110;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                &.adiciona-padding {
                    padding: 10px;
                    overflow: hidden;
                    button {
                        margin: 0 !important;
                    }
                    .icon-adiciona-padding{
                        top: 23px;
                    }
                }
            }
            &--relative {
                position: relative;
            }
        }
    }
    .sd-tour-highlight {
        position: absolute;
    }

</style>