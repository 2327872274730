export class PassaporteUsuarioLogado {
    public idUsuario = '';
    public nomeUsuario = '';
    public chaveUsuario = '';
    public nrTelefone = '';
    public emailUsuario = '';
    public avatar = '';
    public contas = [];
    public assinantes = [];
    public sexo:number| {enum:number, valor:string} = 0;
  
    constructor(u?: Partial<PassaporteUsuarioLogado>) {
        Object.assign(this, u);
    }
  }