<template>
    <transition name="fade">
      <div id="modalBuscaAvancadaInventario" :class="{ 'is-active modal': showModal }" v-if="showModal">
        <div class="modal-background"></div>
        <div class="modal-content modal-sm" style="overflow: hidden">
          <header class="modal-card-head alt-cor-principal">
            <h3 class="modal-card-title">{{titulo}}</h3>
            <button @click="fecharModal" class="close" aria-label="close">
              <i class="fa fa-times"></i>
            </button>
          </header>

          <section class="modal-card-body">
            <p>{{mensagem}}</p>
          </section>

          <footer class="modal-card-foot p-2">
              <div class="column is-3 is-12-mobile">
                <button @click="confirmar" class="button is-primary is-fullwidth">
                  Sim
                </button>
              </div>
              <div class="column is-3 is-12-mobile">
                <button @click="fecharModal" class="button is-default is-fullwidth">
                  Não
                </button>
              </div>
          </footer>
        </div>
      </div>
    </transition>
</template>

<script>

export default {
  name: 'modalConfirmacao',
  props: ['showModal', 'mensagem', 'titulo'],

  methods: {
    confirmar() {
        this.$emit('confirmar');
    },

    fecharModal() {
      this.$emit('fecharModal');
    },
  }
}
</script>

<style scoped>
  .input {
    text-transform: uppercase;
  }
</style>