
import { EMAIL_PATTERN } from '@/constants';
import { Base } from '@/libs/comum/models/base_model';

export class Email extends Base {
    public idEmailApi:string|undefined = undefined;
    public identificacao = '';
    public email = '';
    public principal = false;
    public emailRepetido:boolean|undefined = undefined;


    constructor(e?: Partial<Email>) {
        super();
        Object.assign(this, e);
    }

    isValid() {
        const emailLowerCase = this.email.toLowerCase()
        return emailLowerCase ? (emailLowerCase.match(EMAIL_PATTERN) !== null) : false;
    }

    verificaRepeticaoEmail(emails:Email[]) {
        const _emails = Object.create(null);
        for (let i = 0; i < emails.length; ++i) {
            const email = emails[i].email;
            if (email in _emails) {
                emails[i].emailRepetido = true;
            }
            else {
                emails[i].emailRepetido = undefined;
            }
            if (email) {
                _emails[email] = true;
            }
        }
    }

    verificaPendenciaEmailInvalido() {
        this.removerPendencia('email')
        if (!this.isValid()) {
            this.adicionarPendencia({
                prop: 'email',
                mensagem: 'Informe um <b>E-mail</b> válido.'
            });
            return true;
        }
        return false;
    }

}