import {Bus} from 'ubus';
export const ubus = new Bus();

//export const MS_PESSOA_BASE_API = 'https://pessoa-dev.alterdata.com.br/rest/pessoas/';
export const CORE_BASE_API = '/koopon-core-rest-api/';
export const MS_PESSOA_BASE_API = '/ms-pessoa/pessoas/'
export const KOOPON_PESSOA_BASE_API = '/koopon-pessoa-rest-api/';
export const KOOPON_VALIDACAO_ENTIDADE = '/koopon-validacao-uso-entidade-rest-api/';
export const KOOPON_CORE_API_CNPJ = '/koopon-core-publico-rest-api/publico/cnpj/';
export const KOOPON_CORE_API_CEP = '/koopon-core-publico-rest-api/publico/cep/';
export const KOOPON_CORE_API_MUNICIPIOS = '/koopon-core-publico-rest-api/publico/estados/{UF}/municipios';
export const BACEN_BRASIL = '1058';

export const acessosSistema = {
  movimento: {
      CHAVE_ACESSO: '02e76706d66aa029e2f0ce97ad7df5d0'
  },
  financeiro: {
      CHAVE_ACESSO: 'a492fecbb90ffdca7a571f013dd3807e'
  },
  pessoa: {
    LISTAR: "107",
    CRIAR: '108',
    EXCLUIR: '109',
    EDITAR: '110',
    INCLUIR_ANEXO_PESSOA: '111',
    EXCLUIR_ANEXO_PESSOA: '112',
    INCLUIR_ANOTACAO_PESSOA: '113',
    EXCLUIR_ANOTACAO_PESSOA: '114'
  }
};

export const mfCarregandoEvento = {
  EXIBIR_CARREGANDO: 'mf.carregando:exibir_carregando',
  ESCONDER_CARREGANDO: 'mf.carregando:esconder_carregando'
}

export const mfPessoaEvento = {
  modal: {
    ABRE_MODAL_PESSOA_ESPECIFICA: 'mf.pessoa:abre_modal_pessoa_especifica',
    ABRE_MODAL_TIPO_PESSOA: 'mf.pessoa:abre_modal_tipo_pessoa',
    MODAL_PESSOA_ESPECIFICA_FOI_FECHADO: 'mf.pessoa:modal_pessoa_especifica_foi_fechado',
    MODAL_TIPO_PESSOA_FOI_FECHADO: 'mf.pessoa:modal_tipo_pessoa_foi_fechado',
    EXIBE_MODAL_ALERTA: 'mf.pessoa:exibe_modal_alerta',
  },
  crud: {
    PESSOA_CRIADA: 'mf.pessoa:pessoa_criada',
    PESSOA_ATUALIZADA: 'mf.pessoa:pessoa_atualizada',

    TIPO_PESSOA_CRIADA: 'mf.tipoPessoa:tipoPessoa_criada',
    TIPO_PESSOA_ATUALIZADA: 'mf.tipoPessoa:tipoPessoa_atualizada'
  },
  mock: {
    ENDERECO_PREENCHE_DADOS: 'mf.pessoa:endereco_preenche_dados',
    CONTATO_PREENCHE_DADOS: 'mf.pessoa:contato_preenche_dados',
    COMENTARIOS_PREENCHE_DADOS: 'mf.pessoa:comentarios_preenche_dados',
    ANEXOS_PREENCHE_DADOS: 'mf.pessoa:anexo_preenche_dados',
  },
  tour: {
    EXIBE_TOUR: 'mf.pessoa:exibe_tour'
  }
}

export const kooponPessoaMensagensRetorno = {
    CONFIRMACAO_COMENTARIO: 'Existe um <b>comentário</b> preenchido que não foi adicionado. Continuar <b>SEM</b> a inclusão do comentário?',
    CONFIRMACAO_CPF_CNPJ: 'O <strong>CPF/CNPJ</strong> deste cadastro foi alterado. ' +
    'Ressaltamos a importância dos cadastros serem individuais para preservação do histórico dos registros.</br></br>' +
    'Deseja confirmar essa alteração?',
    INFO_SISTEMAS_COMPLEMENTARES: 'Marcando essa opção, o cadastro de uma pessoa com esse tipo habilitará o campo de login e senha, que serão os dados de acesso aos sistemas integrados.'
}

export const EMAIL_PATTERN = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,63}$/i;

export const ENUM_TIPOS_PESSOA = {
  CLIENTE: {
    codigoTipoPessoa : 0,
    descricao: 'Cliente'
  },
  FUNCIONARIO: {
    codigoTipoPessoa: 1,
    descricao: 'Funcionário'
  },
  FORNECEDOR: {
    codigoTipoPessoa: 2,
    descricao: 'Fornecedor'
  },
  SOCIO: {
    codigoTipoPessoa: 3,
    descricao: 'Sócio'
  },
  TRANSPORTADORA: {
    codigoTipoPessoa: 4,
    descricao: 'Transportadora'
  },
  /* ENTREGADOR: {
    codigoTipoPessoa: 5,
    descricao: 'Entregador'
  },*/
  PERSONALIZADO: {
    codigoTipoPessoa: 6,
    descricao: 'Personalizado'
  }
}

export const ENUM_TIPOS_TELEFONE = [
    {
      enum: 0,
      descricao: 'Telefone'
    },
    {
      enum: 1,
      descricao: 'Celular'
    },
    {
      enum: 2,
      descricao: 'WhatsApp'
    },
    {
      enum: 3,
      descricao: 'Outros'
    }
]

export const ENUM_TIPOS_CONTRIBUINTE = [
    {
      enum: 1,
      descricao: 'Contribuinte de ICMS'
    },
    {
      enum: 2,
      descricao: 'Contribuinte isento'
    },
    {
      enum: 9,
      descricao: 'Não contribuinte'
    }
]

export const ESTADOS = [
    {
      sigla: 'AC',
      nome: 'Acre'
    },
    {
      sigla: 'AL',
      nome: 'Alagoas'
    },
    {
      sigla: 'AP',
      nome: 'Amapá'
    },
    {
      sigla: 'AM',
      nome: 'Amazonas'
    },
    {
      sigla: 'BA',
      nome: 'Bahia'
    },
    {
      sigla: 'CE',
      nome: 'Ceará'
    },
    {
      sigla: 'DF',
      nome: 'Distrito Federal'
    },
    {
      sigla: 'ES',
      nome: 'Espírito Santo'
    },
    {
      sigla: 'GO',
      nome: 'Goiás'
    },
    {
      sigla: 'MA',
      nome: 'Maranhão'
    },
    {
      sigla: 'MT',
      nome: 'Mato Grosso'
    },
    {
      sigla: 'MS',
      nome: 'Mato Grosso do Sul'
    },
    {
      sigla: 'MG',
      nome: 'Minas Gerais'
    },
    {
      sigla: 'PA',
      nome: 'Pará'
    },
    {
      sigla: 'PB',
      nome: 'Paraíba'
    },
    {
      sigla: 'PR',
      nome: 'Paraná'
    },
    {
      sigla: 'PE',
      nome: 'Pernambuco'
    },
    {
      sigla: 'PI',
      nome: 'Piauí'
    },
    {
      sigla: 'RJ',
      nome: 'Rio de Janeiro'
    },
    {
      sigla: 'RN',
      nome: 'Rio Grande do Norte'
    },
    {
      sigla: 'RS',
      nome: 'Rio Grande do Sul'
    },
    {
      sigla: 'RO',
      nome: 'Rondônia'
    },
    {
      sigla: 'RR',
      nome: 'Roraima'
    },
    {
      sigla: 'SC',
      nome: 'Santa Catarina'
    },
    {
      sigla: 'SP',
      nome: 'São Paulo'
    },
    {
      sigla: 'SE',
      nome: 'Sergipe'
    },
    {
      sigla: 'TO',
      nome: 'Tocantins'
    },
    {
      sigla: 'EX',
      nome: 'Exterior'
    }
];

export const TIPOS_ENDERECO = {
  PRINCIPAL: {
    codigo: 1,
    descricao: 'Principal'
  },
  COBRANCA: {
    codigo: 2,
    descricao: 'Cobrança',
  },
  ENTREGA: {
    codigo: 3,
    descricao: 'Entrega',
  }
};

export const TIPOS_ENDERECO_ARRAY = [...Object.values(TIPOS_ENDERECO)];

export const pessoaEspecificaTour = {
  codigo: 26,
  passos: [
    {
        target: '#koopon-modal-pessoa-especifica .passo-1',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Nova Pessoa',
        },
        content: `<p>Informe neste cadastro todos os dados da pessoa que vai ser cadastrada.</p><p>Existem diversos campos disponíveis para atender as suas necessidades.</p>`,
        labels: {
            buttonNext: 'Vamos lá!?'
        },
        params: {
            placement: 'bottom',
            enableScrolling: false,
            highlight: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-2',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'CPF/CNPJ'
        },
        content: `Informe o CPF ou CNPJ da pessoa que está sendo cadastrada. <br>
                  Obs.: Este campo é opcional, sendo obrigatório para geração de documentos fiscais como a NFe.`,
        params: {
            placement: 'right',
            enableScrolling: false
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-3',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Nome'
        },
        content: `Insira aqui o nome da pessoa. Caso seja informado um CNPJ no campo CPF/CNPJ, utilize esse espaço para a Razão Social. Em seguida, abaixo deste, será apresentado o campo Nome Fantasia.`,
        params: {
            placement: 'top',
            enableScrolling: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-4',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Tipo de Pessoa'
        },
        content: `Informe se a pessoa é do tipo Cliente, Fornecedor, Funcionário ou outro.`,
        params: {
            placement: 'bottom',
            enableScrolling: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-5',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Contribuição de ICMS'
        },
        content: `Selecione dentre as opções o tipo de contribuição da pessoa, podendo ser Contribuinte de ICMS, Não contribuinte de ICMS e ISENTO.`,
        params: {
            placement: 'bottom',
            enableScrolling: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-6',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Inscrição Estadual, municipal e SUFRAMA'
        },
        content: `Se disponíveis, forneça esses dados.`,
        params: {
            placement: 'top',
            enableScrolling: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-7',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Categoria de Receita e Categoria de Despesa'
        },
        content: `As categorias são importantes, pois serão utilizadas para classificar seus registros financeiros ao associar esta pessoa a um lançamento.`,
        params: {
            placement: 'top',
            enableScrolling: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-8',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Centro de Custo'
        },
        content: `O Centro de custo, facilita o controle e a análise dos custos por área.`,
        params: {
            placement: 'top',
            enableScrolling: true
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-endereco',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Endereço'
        },
        content: `Informe o endereço desta pessoa, sendo possível incluir até três endereços por cadastro.`,
        params: {
            placement: 'top',
            enableScrolling: true
        },
        evento: {
          adiciona: {
              nome: mfPessoaEvento.mock.ENDERECO_PREENCHE_DADOS
          },
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-contato',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Contato'
        },
        content: `Utilize a aba Contato para adicionar informações de telefone, e-mail ou site.`,
        params: {
            placement: 'top',
            enableScrolling: false
        },
        evento: {
          adiciona: {
              nome: mfPessoaEvento.mock.CONTATO_PREENCHE_DADOS
          },
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-comentarios',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Comentários'
        },
        content: `Precisa incluir informações específicas para cada cliente? Utilize o campo Comentários.`,
        params: {
            placement: 'top',
            enableScrolling: false
        },
        evento: {
          adiciona: {
              nome: mfPessoaEvento.mock.COMENTARIOS_PREENCHE_DADOS
          },
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-anexos',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Anexos'
        },
        content: `Caso seja necessário anexar contratos ou documentos relacionados a esta pessoa, utilize este espaço para adicionar vários anexos ao cadastro. `,
        params: {
            placement: 'top',
            enableScrolling: false
        },
        evento: {
          adiciona: {
              nome: mfPessoaEvento.mock.ANEXOS_PREENCHE_DADOS
          },
        },
    },
    {
        target: '#koopon-modal-pessoa-especifica .passo-9',
        webComponent: {
          name: 'mf-erpforme-pessoa'
        },
        header: {
            title: 'Gravar'
        },
        content: `Para gravar o cadastro desta pessoa, clique em "Gravar".`,
        params: {
            placement: 'top',
            enableScrolling: false
        }
    }
  ]
}