<template>
<transition-group name="fade">
  <div id="koopon-modal-pessoa-especifica" class="modal modal-no-overflow" :class="{'is-active':exibeModal}" v-show="exibeModal" key="koopon-modal-pessoa-especifica">
    <div class="modal-content">
        <header class="modal-card-head alt-cor-principal passo-1">
            <h3 class="modal-card-title" v-show="pessoa.isNew()">Nova Pessoa</h3>
            <h3 class="modal-card-title" v-show="!pessoa.isNew()">Editar Pessoa</h3>
            <button @click="fechaModal(true)" class="close" aria-label="close">
            <i class="fa fa-times"></i>
            </button>
        </header>

        <section class="modal-card-body">
            <div class="columns is-flex-wrap-wrap">
                <div class="column is-4 passo-2">
                    <label>CPF/CNPJ</label>
                    <input type="text" 
                        id="mf-pessoa-erpforme-especifica-input-cpf-cnpj"
                        maxlength="18" 
                        class="input" 
                        v-model="pessoa.cpfCnpj" 
                        v-mask="mascaraCpfCnpj()"
                        :class="{'is-danger': pessoa.propPossuiPendencia('cpfCnpj')}"
                        @blur="pessoa.validarCpfCnpj(cpfCnpjJaExiste);"
                        @focus="pessoa.removerPendencia('cpfCnpj')"/>
                    <span class="help is-danger" v-show="pessoa.propPossuiPendencia('cpfCnpj')" v-html="pessoa.obterMensagemPendencia('cpfCnpj')"></span>
                </div>

                <div class="column is-8 passo-3">
                    <label>Nome</label>
                    <input type="text" 
                        id="mf-pessoa-erpforme-especifica-input-nome-pessoa"
                        v-model="pessoa.nome" 
                        :disabled="carregandoCnpj"
                        :class="{'is-danger': pessoa.propPossuiPendencia('nome')}"
                        @focus="pessoa.removerPendencia('nome')"
                        @change="pessoa.validarNomePessoa()"
                        maxlength="99" 
                        class="input"/>
                    <span class="help is-danger" v-show="pessoa.propPossuiPendencia('nome')" v-html="pessoa.obterMensagemPendencia('nome')"></span>
                </div>
            </div>

            <div class="columns" v-show="pessoa.isJuridica()">
                <div class="column is-12">
                    <label>Nome fantasia</label>
                    <input type="text" 
                        id="mf-pessoa-erpforme-especifica-input-nome-fantasia"
                        v-model="pessoa.nomeFantasia" 
                        :disabled="carregandoCnpj" 
                        maxlength="60" 
                        class="input" />
                </div>
            </div>

            <div class="columns">
                <div class="column passo-4" :class="[pessoa.isJuridica() ? 'is-12' : 'is-9']">
                    <label>
                        Tipos de pessoa
                        <span class="has-tooltip-multiline has-text-centered" v-if="tiposPessoaPermitidos.length" :data-tooltip="avisoTiposDePessoaPermitidos">
                            <i class="fa fa-info-circle" style="font-size:16px;" ></i>
                        </span>
                    </label>
                    <div class="columns-row">
                        <vue-multiselect
                        id="mf-pessoa-erpforme-especifica-select-tipos-pessoa-pessoa"
                        v-model="pessoa.tiposPessoa"
                        :options="tiposPessoa"
                        :multiple="true"
                        :max-height="320"
                        label="descricao"
                        track-by="idTipoPessoa"
                        :close-on-select="true"
                        :show-labels="false"
                        :disabled="!permiteEditarTipos"
                        placeholder=""
                        :class="{'is-danger': pessoa.propPossuiPendencia('listaTpPessoa')}"
                        @remove="verificaRemocaoTipoPessoa"
                        @select="onChangeTiposPessoa();">
                        <span slot="noResult">Nenhum resultado encontrado</span>
                    </vue-multiselect>
                    <button class="btn-criar-novo" @click="abrirModalTipoPessoa()">                     
                        <span class="tooltip-top" data-tooltip="Criar Novo">
                            <i class="fa fa-plus" style="color: #777;" ></i>
                        </span>
                    </button>
                </div>
                <span class="help is-danger" v-show="pessoa.propPossuiPendencia('listaTpPessoa')" v-html="pessoa.obterMensagemPendencia('listaTpPessoa')"></span>
                </div>
                <div class="column is-3" v-show="!pessoa.isJuridica()">
                    <label>Data de Nascimento</label>
                    <v-date-picker
                        v-model='pessoa.dataNascimento'
                        class="alt-data-filtro mf-pessoa-data-nascimento"
                        color="purple"
                        :first-day-of-week="1"
                        :model-config="formatoData"
                        :popover='{visibility: "click"}'>
                        <template v-slot="{ inputValue, inputEvents, isDragging, togglePopover }">
                            <div class="is-relative is-flex-grow-1">
                            <input
                                id="mf-pessoa-erpforme-especifica-input-data-nascimento"
                                class="input"
                                :class="{ 'has-text-grey-light' : isDragging, 'is-danger' : pessoa.propPossuiPendencia('dataNascimento') }"
                                :value="inputValue"
                                v-on="inputEvents"
                                v-on:blur="pessoa.removerPendencia('dataNascimento')"
                                v-on:focus="pessoa.removerPendencia('dataNascimento')"
                                v-mask="'##/##/####'"
                                placeholder= "__/__/____"
                            />
                            <span class="far fa-calendar-alt pointer-events-none is-clickable"
                                @click="togglePopover({ placement: 'bottom-start' })">
                            </span>
                            </div>
                        </template>
                    </v-date-picker>
                    <span class="help is-danger" v-show="pessoa.propPossuiPendencia('dataNascimento')" v-html="pessoa.obterMensagemPendencia('dataNascimento')"></span>
                </div>
            </div>

            <div class="columns">
                <div class="column is-3 passo-5">
                    <label>Contribuição de ICMS</label>
                    <vue-multiselect 
                        id="mf-pessoa-erpforme-especifica-select-tipos-contribuinte-pessoa"
                        v-model="pessoa.tipoContribuinteInscricaoEstadual" 
                        :options="tiposContribuinte.map(t => t.enum)" 
                        :custom-label="opt => getDescricaoEnum(opt, tiposContribuinte)"
                        :close-on-select="true" 
                        :show-labels="false"
                        placeholder=""
                        >
                        <span slot="noResult">Nenhum resultado encontrado</span>
                    </vue-multiselect>
                </div>

                <div class="column is-9 passo-6">
                    <div class="columns">
                        <div class="column is-4">
                            <label>Inscrição estadual</label>
                            <input type="text"
                                id="mf-pessoa-erpforme-especifica-input-inscricao-estadual" 
                                class="input" 
                                :class="{'is-danger': pessoa.propPossuiPendencia('inscricaoEstadual')}"
                                maxlength="14" 
                                v-mask="'##############'"
                                @focus="pessoa.removerPendencia('inscricaoEstadual')"
                                v-model="pessoa.inscricaoEstadual"/>
                            <span class="help is-danger" v-show="pessoa.propPossuiPendencia('inscricaoEstadual')" v-html="pessoa.obterMensagemPendencia('inscricaoEstadual')"></span>
                        </div>
        
                        <div class="column is-4">
                            <label>Inscrição municipal</label>
                            <input type="text" 
                                    id="mf-pessoa-erpforme-especifica-input-inscricao-municipal"
                                    class="input" 
                                    maxlength="15" 
                                    v-mask="'XXXXXXXXXXXXXXX'"
                                    v-model="pessoa.inscricaoMunicipal" />
                        </div>
        
                        <div class="column is-4">
                            <div>
                            <label>SUFRAMA</label>
                            <input type="text" 
                                id="mf-pessoa-erpforme-especifica-input-suframa"
                                class="input"
                                :class="{'is-danger': pessoa.propPossuiPendencia('suframa')}"
                                maxlength="9"
                                v-mask="'#########'"
                                v-model="pessoa.suframa"
                                @focus="pessoa.removerPendencia('suframa')"/>
                            <span class="help is-danger" v-show="pessoa.propPossuiPendencia('suframa')" v-html="pessoa.obterMensagemPendencia('suframa')"></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="columns" v-if="temCategoriasReceitas() || temCategoriasDespesas() || temCentrosDeCusto()">
                <div class="column is-8 passo-7" v-if="temCategoriasReceitas() || temCategoriasDespesas()">
                    <div class="columns">
                        <div class="column is-6"  v-if="temCategoriasReceitas()">
                            <label>Categoria de Receita</label>
                            <div class="multiselect-buttons-container">
                                <vue-multiselect 
                                    id="mf-pessoa-erpforme-especifica-select-tipos-categoria-receita"
                                    v-model="pessoa.categoriaEntradaPadrao" 
                                    :options="categoriasReceitas"
                                    group-values="itens"
                                    group-label="grupo" 
                                    label="nome"
                                    track-by="idCategoria"
                                    :close-on-select="true" 
                                    :show-labels="false"
                                    @select="pessoa.atualizaSelectCadastroReceita()"
                                    :disabled="pessoa.atualizaSelectCadastroReceita()"
                                    placeholder=""
                                    >
                                    <span slot="noResult">Nenhum resultado encontrado</span>
                                </vue-multiselect>
                                <button type="button" class="button btn-default" @click="pessoa.categoriaEntradaPadrao = undefined" :disabled="!pessoa.categoriaEntradaPadrao">
                                    <i class="fas fa-eraser"></i>
                                </button>
                            </div>
                        </div>
                        <div class="column is-6"  v-if="temCategoriasDespesas()">
                            <label>Categoria de Despesa</label>
                            <div class="multiselect-buttons-container">
                                <vue-multiselect 
                                    id="mf-pessoa-erpforme-especifica-select-tipos-categoria-despesa"
                                    v-model="pessoa.categoriaSaidaPadrao" 
                                    :options="categoriasDespesas" 
                                    group-values="itens"
                                    group-label="grupo"
                                    label="nome"
                                    track-by="idCategoria"
                                    :close-on-select="true" 
                                    :show-labels="false"
                                    :disabled="pessoa.atualizaSelectCadastroDespesa()"
                                    placeholder=""
                                    >
                                    <span slot="noResult">Nenhum resultado encontrado</span>
                                </vue-multiselect>
                                <button type="button" class="button btn-default" @click="pessoa.categoriaSaidaPadrao = undefined" :disabled="!pessoa.categoriaSaidaPadrao">
                                    <i class="fas fa-eraser"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-4 passo-8"  v-if="temCentrosDeCusto()">
                    <label>Centro de Custo</label>
                    <div class="multiselect-buttons-container">
                        <vue-multiselect 
                            id="mf-pessoa-erpforme-especifica-select-tipos-centro-de-custo"
                            v-model="pessoa.centroCustoPadrao" 
                            :options="centrosDeCusto" 
                            label="nome"
                            track-by="nome"
                            :close-on-select="true" 
                            :show-labels="false"
                            :disabled="pessoa.atualizaSelectCentroDeCusto()"
                            placeholder=""
                            >
                            <span slot="noResult">Nenhum resultado encontrado</span>
                        </vue-multiselect>
                        <button type="button" class="button btn-default" @click="pessoa.centroCustoPadrao = undefined" :disabled="!pessoa.centroCustoPadrao">
                            <i class="fas fa-eraser"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="columns passo-endereco passo-contato passo-comentarios passo-anexos">
                <div class="column is-12">
                    <tabs animation="slide" :only-fade="false" :key="keyAbas" ref="abas">
                        <tab-pane label="Endereço" :icon="getIconeAlerta(pessoa.endereco.possuiPendencias())">
                            <EnderecoComponent v-show="exibeModal" ref="enderecoComponentRef"/>
                        </tab-pane>
                        <tab-pane label="Contato" :icon="getIconeAlerta(pessoa.temPendenciasContato)">
                            <div class="columns">
                                <div class="column">
                                    <label>Site</label>
                                    <input type="text" 
                                        id="mf-pessoa-erpforme-especifica-input-site"
                                        maxlength="99" 
                                        v-model="pessoa.site" 
                                        class="input" />
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-12">
                                    <div class="koopon-divisor-campos alt-espacamento-top">
                                        <label class="has-text-weight-bold has-text-grey">E-mails</label>
                                    </div>
                                </div>
                            </div>
                            <div id="emails-cabecalho-labels" class="columns is-variable is-2 is-hidden-mobile" v-show="pessoa.emails.length">
                                <div class="column is-10">E-mail</div>
                                <div class="column is-2">Principal</div>
                            </div>
                            <div id="container-emails" class="mb-3" v-show="pessoa.emails.length">
                                <div v-for="(email, index) in pessoa.emails"
                                    :key="index"
                                    class="container-email columns is-variable is-2">
                                    <div class="column is-10">
                                        <label class="is-hidden-tablet">E-mail</label>
                                        <input type="text" maxlength="60"
                                            v-model="email.email"
                                            @blur="email.verificaPendenciaEmailInvalido();email.verificaRepeticaoEmail(pessoa.emails)"
                                            @focus="pessoa.removePendenciasContato(email.propPossuiPendencia('email') || !!email.emailRepetido)"
                                            :class="{'is-danger': email.propPossuiPendencia('email') || !!email.emailRepetido}"
                                            :disabled="carregandoCnpj"
                                            class="input" />
                                        <span class="help is-danger" v-show="email.emailRepetido">E-mail já utilizado</span>
                                        <span class="help is-danger" v-show="email.propPossuiPendencia('email')" v-html="email.obterMensagemPendencia('email')"></span>
                                    </div>
                                    <div class="column is-2">
                                        <label class="is-hidden-tablet">Principal</label>
                                        <label class="is-clickable checkbox-principal">
                                            <input type="checkbox"
                                                id="mf-pessoa-erpforme-especifica-select-telefone-principal"
                                                v-model="email.principal"
                                                @change="atualizaEmailPrincipal(email)"
                                                :disabled="email.principal" />
                                            <span class="alt-checkbox no-style"></span>
                                        </label>
                                    </div>
                                    <span class="icone-excluir-email" @click="removeEmail(index, email)" title="Remover E-mail">
                                        <i class="fa fa-times"></i><span class="sr-only">Excluir</span>
                                    </span>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <button @click="adicionaEmail()" class="button" :class="{'is-pulled-right': pessoa.emails.length}"><i class="fas fa-plus mr-2"></i>Adicionar E-mail</button>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-12">
                                    <div class="koopon-divisor-campos alt-espacamento-top">
                                        <label class="has-text-weight-bold has-text-grey">Telefones</label>
                                    </div>
                                </div>
                            </div>
                            <div id="telefones-cabecalho-labels" class="columns is-variable is-2 is-hidden-mobile" v-show="pessoa.telefones.length">
                                <div class="column is-3">Tipo</div>
                                <div class="column is-3">Número</div>
                                <div class="column is-4">Contato</div>
                                <div class="column is-2">Principal</div>
                            </div>
                            <div id="container-telefones" class="mb-3" v-show="pessoa.telefones.length"> 
                                <div v-for="(telefone, index) in pessoa.telefones" 
                                    :key="index" 
                                    class="container-telefone columns is-variable is-2">
                                    <div class="column is-3">
                                        <label class="is-hidden-tablet">Tipo</label>
                                        <vue-multiselect 
                                            id="mf-pessoa-erpforme-especifica-select-telefone-tipo"
                                            select-overflow
                                            v-model="telefone.tipo"
                                            :options="tiposTelefones.map(t => t.enum)" 
                                            :custom-label="opt => getDescricaoEnum(opt, tiposTelefones)"
                                            :close-on-select="true"
                                            :show-labels="false"
                                            :allow-empty="false"
                                            :class="{'is-danger': telefone.propPossuiPendencia('tipo')}"
                                            @input="telefone.verificaPendenciaTipoInvalido()"
                                            @open="pessoa.removePendenciasContato(telefone.propPossuiPendencia('tipo'))"
                                            placeholder=""
                                            >
                                            <span slot="noResult">Nenhum resultado encontrado</span>
                                        </vue-multiselect>
                                        <span class="help is-danger" v-show="telefone.propPossuiPendencia('tipo')" v-html="telefone.obterMensagemPendencia('tipo')"></span>
                                    </div>
                                    <div class="column is-3">
                                        <label class="is-hidden-tablet">Número</label>
                                        <input type="text" maxlength="16"
                                            id="mf-pessoa-erpforme-especifica-select-telefone-numero" 
                                            v-model="telefone.numero" 
                                            :disabled="carregandoCnpj"
                                            v-mask="mascaraTelefone(telefone.numero)"
                                            @blur="telefone.verificaPendenciaNumeroInvalido();telefone.verificaRepeticaoNumero(pessoa.telefones)"
                                            @focus="pessoa.removePendenciasContato(telefone.propPossuiPendencia('numero') || !!telefone.numeroRepetido)"
                                            :class="{'is-danger': telefone.propPossuiPendencia('numero') || !!telefone.numeroRepetido}"
                                            class="input" />
                                        <span class="help is-danger" v-show="telefone.numeroRepetido">Número já utilizado</span>
                                        <span class="help is-danger" v-show="telefone.propPossuiPendencia('numero')" v-html="telefone.obterMensagemPendencia('numero')"></span>
                                    </div>
                                    <div class="column is-4">
                                        <label class="is-hidden-tablet">Contato</label>
                                        <input  type="text" 
                                                id="mf-pessoa-erpforme-especifica-select-telefone-contato"
                                                maxlength="99" 
                                                v-model="telefone.contato" 
                                                class="input"/>
                                    </div>
                                    <div class="column is-2">
                                        <label class="is-hidden-tablet">Principal</label>
                                        <label class="is-clickable checkbox-principal">
                                            <input type="checkbox"
                                                id="mf-pessoa-erpforme-especifica-select-telefone-principal" 
                                                v-model="telefone.principal" 
                                                @change="atualizaTelefonePrincipal(telefone)" 
                                                :disabled="telefone.principal" />
                                            <span class="alt-checkbox no-style"></span>
                                        </label>
                                    </div>
                                    <span class="icone-excluir-telefone" @click="removerTelefone(index, telefone)" title="Remover telefone">
                                        <i class="fa fa-times"></i><span class="sr-only">Excluir</span>
                                    </span>
                                </div>
                            </div>

                            <button @click="adicionarTelefone()" class="button" :class="{'is-pulled-right': pessoa.telefones.length}"><i class="fas fa-plus mr-2"></i>Adicionar Telefone</button>
                        </tab-pane>
                        <tab-pane label="Comentários" :icon="getIconeAlerta(temPendenciasComentario)" v-if="podeIncluirAnotacao">
                            <koopon-comum-comentarios
                                v-if="exibeModal"
                                :anotacoes="pessoa.anotacoes"
                                :usuario-autenticado="usuarioAutenticado"
                                :exibe-hint-comentario="temPendenciasComentario"
                                @update="pessoa.anotacoes = $event"
                                @atualizaCampoComentario="atulizaCampoComentario($event)"/>
                        </tab-pane>
                        <tab-pane label="Anexos" v-if="podeIncluirAnexo">
                            <pessoa-anexos v-if="exibeModal"
                                :value="pessoa.anexos" 
                                @atualizaAnexosExcluidos="anexosExcluidos = $event"
                                @update="pessoa.anexos = $event" />
                        </tab-pane>
                        <tab-pane label="Dados dos Veículos" :icon="getIconeAlerta(pessoa.temPendenciasVeiculos)" v-if="pessoa.possuiTiposPessoa(enumTipoPessoa.TRANSPORTADORA)">
                            <div id="veiculos-cabecalho-labels" class="columns is-variable is-2 is-hidden-mobile" v-show="pessoa.veiculosTransportadora.length">
                                <div class="column is-3">Placa</div>
                                <div class="column is-3">UF</div>
                                <div class="column is-4">Registro ANTT</div>
                                <div class="column is-2"></div>
                            </div>
                            <div id="container-veiculos" class="mb-3" v-show="pessoa.veiculosTransportadora.length"> 
                                <div v-for="(veiculoTransportadora, index) in pessoa.veiculosTransportadora"
                                    :key="index" 
                                    class="container-veiculo columns is-variable is-2">
                                    <div class="column is-3">
                                        <label class="is-hidden-tablet">Placa</label>
                                        <input type="text"
                                                id="koopon-pessoa-especifica-input-veiculo-transportadora-placa"
                                                maxlength="7"
                                                class="input"
                                                :class="{'is-danger': veiculoTransportadora.propPossuiPendencia('placa') || !!veiculoTransportadora.placaRepetida}"
                                                v-model="veiculoTransportadora.placa"
                                                @input="veiculoTransportadora.placa = veiculoTransportadora.placa.toUpperCase();"
                                                @blur="veiculoTransportadora.verificaPendenciaPlacaInvalida();veiculoTransportadora.verificaRepeticaoPlaca(pessoa.veiculosTransportadora);"
                                                @focus="pessoa.removePendenciasVeiculos(veiculoTransportadora.propPossuiPendencia('placa') || !!veiculoTransportadora.placaRepetida)"/>
                                        <span class="help is-danger" v-show="veiculoTransportadora.placaRepetida">Placa já utilizada</span>
                                        <span class="help is-danger" v-show="veiculoTransportadora.propPossuiPendencia('placa')" v-html="veiculoTransportadora.obterMensagemPendencia('placa')"></span>
                                    </div>
                                    <div class="column is-3">
                                        <label class="is-hidden-tablet">UF</label>
                                        <vue-multiselect 
                                            class="koopon-comum-formulario-endereco-select-ufs"
                                            v-model="veiculoTransportadora.uf" 
                                            :options="estados"
                                            track-by="sigla"
                                            label="sigla"
                                            :close-on-select="true" 
                                            :show-labels="false"
                                            :allow-empty="false"
                                            placeholder="">
                                            <span slot="noResult">Nenhum resultado encontrado</span>
                                        </vue-multiselect>
                                    </div>
                                    <div class="column is-5">
                                        <label class="is-hidden-tablet">Registro ANTT</label>
                                        <input type="text"
                                                id="koopon-pessoa-especifica-input-veiculo-transportadora-registro-antt"
                                                class="input"
                                                v-model="veiculoTransportadora.antt"/>
                                    </div>
                                    <span class="icone-excluir-veiculo" @click="removerVeiculo(index, veiculoTransportadora)" title="Remover veículo">
                                        <i class="fa fa-times"></i><span class="sr-only">Excluir</span>
                                    </span>
                                </div>
                            </div>

                            <button @click="adicionarVeiculo()" class="button" :class="{'is-pulled-right': pessoa.veiculosTransportadora.length}"><i class="fas fa-plus mr-2"></i>Adicionar Veículo</button>
                        </tab-pane>
                    </tabs>
                </div>
            </div>

        </section>
    
        <footer class="modal-card-foot">
            <div class="alt-koopon-modal-check-ativo alt-koopon-modal-check-width-auto mr-2" v-show="!pessoa.isNew()">
                <label class="is-clickable mb-0 mr-1">
                    <input type="checkbox" v-model="pessoaStatus" @change="atualizaStatusPessoa(pessoaStatus)"/>
                    <span class="alt-checkbox"></span> <span class="alt-checkbox-label">Ativo</span>
                </label>
                <i class="fa fa-info-circle is-clickable has-text-grey" style="font-size: 16px;" @click="exibeInfoPessoaDesabilitada()"></i>
            </div>
            
            <div class="mr-2" v-show="pessoa.isNew() && permiteCriacaoDeOutraPessoa">
                <label class="is-clickable has-text-left mb-0">
                    <input type="checkbox" v-model="criarOutraPessoa" id="mf-pessoa-erpforme-especifica-input-criar-outra-pessoa"/>
                    <span class="alt-checkbox"></span> <span class="alt-checkbox-label">Criar outra</span>
                </label>
            </div>
            <span class="passo-9 adiciona-padding mr-2"><button @click="gravar(pessoa, criarOutraPessoa)" id="mf-pessoa-erpforme-especifica-input-botao-gravar" class="button is-primary">Gravar</button></span>
            <button @click="fechaModal(true)" id="mf-pessoa-erpforme-especifica-input-botao-fechar-modal" class="button is-default">Cancelar</button>
        </footer>

        <TourVirtual
            ref="tourVirutal"
            :steps="tourPessoa.passos"
            :codigoFuncionalidade="tourPessoa.codigo"
            @onSkip="selecionaAba(0)"
            @onFinish="selecionaAba(0)"
        />
    </div>
  </div>
    <kooponPessoaTipoPessoa
    v-if="modalTipoPessoaOpen"    
    key="modal-tipo-pessoa"
    @fecharModalTipoPessoa="fecharModalTipoPessoa()"
    @tipoPessoaCriado="atualizarListaTiposPessoa"
    :idTipoPessoa="idTipoPessoa"
    />
    <ModalConfirmacao
      v-if="exibeModalConfirmacao" 
      key="modal-confirmacao"
      @fecharModal="fechaModalConfirmacao"
      @confirmar="funcaoConfirmar(pessoa)"
      :titulo="tituloModalConfirmacao"
      :mensagem="msgModalConfirmacao"
      :showModal="exibeModalConfirmacao"/>
</transition-group>
</template>

<script lang="ts">
import { Tabs, TabPane } from 'vue-bulma-tabs'
import { ubus, 
        mfPessoaEvento,
         acessosSistema,
         TIPOS_ENDERECO,
         pessoaEspecificaTour,
         ESTADOS as estados,
         ENUM_TIPOS_PESSOA as enumTipoPessoa, 
         ENUM_TIPOS_CONTRIBUINTE as tiposContribuinteEnum, 
         ENUM_TIPOS_TELEFONE as tiposTelefones} from '@/constants';
import { enumTiposPessoaType } from '@/types'
import ErrosBackend from '../libs/comum/helpers/comum_error_parse';
import ModalHtml from "../libs/comum/helpers/comum_modal_html_clipped";
import EnderecoComponent from "@/components/endereco/EnderecoComponent.vue";
import kooponComumComentarios from "@/components/pessoa_comentarios.vue";
import pessoaAnexos from "@/components/pessoa_anexos.vue";
import ModalConfirmacao from '@/libs/comum/modal_confirmacao.vue';
import kooponPessoaTipoPessoa from '@/components/tipoPessoa/TipoPessoa.vue';
import pessoaService from "../services/pessoa_service";
import pessoaAnexoService from "../services/anexo_service"
import customEvents from "../services/custom_events";
import cnpjService from "@/libs/comum/services/comum_service_cnpj_service";
import PassaporteProdutosAcessos from "@/libs/passaporte/models/passaporte_produtos_acessos";
import passaporteUsuarioLogadoManager from '@/libs/passaporte/managers/passaporte_usuario_logado_manager';
import TourVirtual from '@/components/comum/comum_tour.vue'
import { Pessoa } from '../models/pessoa_model';
import { Telefone } from '@/models/telefone_model';
import { Veiculo } from '@/models/veiculo_model';
import { Email } from "@/models/email_model";
import { Endereco } from '@/models/endereco_model';
import { isEqual, cloneDeep } from 'lodash';
import enderecoStore from '@/stores/endereco_store';
import { TipoPessoa } from '@/models/tipo_pessoa_model';
// const MSG_CONFIRMACAO_COMENTARIO = 'Existe um <b>comentário</b> preenchido que não foi adicionado. Continuar <b>SEM</b> a inclusão do comentário?';

const TEMPLATE_INFO_PESSOA_DESABILITADA = `
    <div class="row">
        <div class='col-md-12'>
            <p>Caso esta pessoa seja <strong>desativada</strong> ela <strong>NÃO</strong> poderá ser selecionada.</p>
        </div>
    </div>
`; 

export default {
    name: 'kooponPessoaPessoaEspecifica',

    components: {
        Tabs,
        TabPane,
        EnderecoComponent,
        kooponComumComentarios,
        pessoaAnexos,
        ModalConfirmacao,
        kooponPessoaTipoPessoa,
        TourVirtual
    },

    data: () => ({
        pessoa: new Pessoa(),
        pessoaStatus: true,
        originalCpfCnpj: '',
        originalNome: '',
        cpfCnpjJaExiste: false,
        estados: estados,
        anexoJaExiste: false,
        carregandoCnpj: false,
        carregandoEndereco: false,
        criarOutraPessoa: false,
        permiteCriacaoDeOutraPessoa: true,
        tiposContribuinte: tiposContribuinteEnum,
        textoParaComentario: '',
        temPendenciasComentario: false,
        enumTipoPessoa: enumTipoPessoa,
        tiposPessoa: [] as TipoPessoa[],
        tiposPessoaPermitidos: [] as number[],
        avisoTiposDePessoaPermitidos: '',
        centrosDeCusto: [],
        categoriasReceitas: [] as any[],
        categoriasDespesas: [] as any[],
        tiposTelefones: tiposTelefones,
        possuiAcessoMovimento: false,
        podeIncluirAnotacao: false,
        podeIncluirAnexo: false,
        anexosExcluidos: [],
        exibeModal: false,
        tituloModalConfirmacao: '',
        msgModalConfirmacao: '',
        exibeModalConfirmacao: false,
        permiteEditarTipos: true,
        modalTipoPessoaOpen: false,
        fechaModalConfirmacao: function() {this.exibeModalConfirmacao = false},
        funcaoConfirmar: function(pessoa: Pessoa) {this.exibeModalConfirmacao = false},
        formatoData: {
            type: 'string',
            mask: 'YYYY/MM/DD'
        },
        tokenEvento: '',
        keyAbas: 0,
        cloneEnderecos: [] as Endereco[], 
        usuarioAutenticado: passaporteUsuarioLogadoManager.retorna(),
        componenteTipoPessoaPorEvento: false,
        idTipoPessoa: 0,
        tourPessoa: structuredClone(pessoaEspecificaTour)
    }),

    watch: {
        async cpfCnpj(newValue, oldValue) {
            this.cpfCnpjJaExiste = false;

            const cpfCnpj = newValue ? newValue.trim().replace(/\D/g, '') : '';
            const cpfCnpjAnterior = oldValue ? oldValue.trim().replace(/\D/g, '') : '';

            if (cpfCnpj !== cpfCnpjAnterior) {
                if(!cpfCnpj.length || cpfCnpj === this.originalCpfCnpj) {
                    this.pessoa.removerPendencia('cpfCnpj');
                }
                else if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
                    if (!this.pessoa.validarCpfCnpj(false)) return;

                    await this.verificaCpfCnpjExistente();

                    if (!this.cpfCnpjJaExiste && this.pessoa.isJuridica()) {
                        this.buscaDadosCnpj();
                    }
                }
            }
        }
    },

    computed: {
        cpfCnpj() {
            return this.pessoa.cpfCnpj.replace(/\D/g, '');
        },
        enderecos() {
            return enderecoStore.state.enderecos;   
        },
        manutencaoIniciada() {
            return enderecoStore.state.manutencaoIniciada;   
        },
        enderecoInvalido() {
            return enderecoStore.state.enderecoInvalido;   
        },
        tiposEndereco() {
            return TIPOS_ENDERECO;
        }
    },

    methods: {
        async verificaCpfCnpjExistente() {
            if (this.cpfCnpj && this.pessoa.cpfCnpjValido()) {
                customEvents.exibeCarregando();

                try {
                    this.cpfCnpjJaExiste = await pessoaService.obterPorCpfCnpj(this.cpfCnpj);

                    if (this.cpfCnpjJaExiste) {
                        this.pessoa.validarCpfCnpj(this.cpfCnpjJaExiste)
                    }
                } catch(error) {
                    this.cpfCnpjJaExiste = false;
                }
                finally {
                    customEvents.escondeCarregando();
                }
            }
        },

        buscaDadosCnpj() {
            customEvents.exibeCarregando('Carregando dados pelo CNPJ...');

            this.carregandoCnpj = true;

            cnpjService.obterPorCnpj(this.cpfCnpj)
                .then((info) => {
                    if (info.email) {
                        info.emails = [{
                        email: info.email
                            }];
                    } else {
                         info.emails = [];
                    }
                        this.pessoa.estende(info);
                        this.adicionarTelefoneCnpj(info.telefone);
                        this.atualizaEmailPrincipal(this.pessoa.emails[0]);

                        if (info.endereco) this.adicionarEnderecoPorCnpj(info.endereco);
                    })
                .finally(() => {
                    this.carregandoCnpj = false;
                    customEvents.escondeCarregando();
                });
        },
        async abrirModalTipoPessoa (){ 
            this.idTipoPessoa = 0;          
            this.modalTipoPessoaOpen = true;
            if(this.modalTipoPessoaOpen){
                this.exibeModal = false;
            } else {
                this.exibeModal = true;
            }

        },
        async gravar(pessoa: Pessoa, criarOutro:boolean) {
            const valido = pessoa.validar(this.cpfCnpjJaExiste);            
            if (!pessoa.isJuridica()) {
                pessoa.nomeFantasia = undefined;
            }

            if (!valido || this.textoParaComentario) {
                this.temPendenciasComentario = !!this.textoParaComentario;
                return;
            }

            if (this.manutencaoIniciada) {
                this.$refs.enderecoComponentRef?.gravarManutencaoEndereco();  

                if (this.enderecoInvalido) return;
            }

            if (pessoa.enderecos.length && !pessoa.existeEnderecoPrincipal()) {
                this.$alert({
                    tipo: 'warning',
                    message: `É necessário informar um endereço como 
                        <strong>principal</strong> no cadastro da pessoa.`
                });

                return;
            }

            if (!pessoa.idPessoa) {
                return this.criar(pessoa, criarOutro);
            }

            const atualCpfCnpj = pessoa.cpfCnpj ? pessoa.cpfCnpj.trim().replace(/\D/g, '') : '';
            const atualNome = pessoa.nome ? pessoa.nome.trim() : '';

            if ((!!pessoa.idPessoa) && (atualCpfCnpj !== this.originalCpfCnpj || atualNome !== this.originalNome.trim())) {
                var responseVerificaMovimentos = await this.verificarMovimentos(pessoa)
            }

            if (!!responseVerificaMovimentos && responseVerificaMovimentos.length) {
                this.configuraModalConfirmacaoCpfCnpj(pessoa)
                this.exibeModal = false;
                return this.abreModalConfirmacao('Confirmar Ação','Essa pessoa já possui movimentação. A alteração do CNPJ/CPF ou do nome terá influência nas movimentações dessa pessoa. Deseja confirmar essa alteração?')
            }

            return this.atualizar(pessoa, pessoa.idPessoa);
        },

        async verificarMovimentos(pessoa: Pessoa) {
            const resposta = await pessoaService.verificarMovimentos(pessoa.idPessoa);
            return resposta;
        },

        configuraModalConfirmacaoCpfCnpj() {
            this.fechaModalConfirmacao = () => {
                this.pessoa.cpfCnpj = this.formataCpfCnpj(this.originalCpfCnpj);
                this.pessoa.nome = this.originalNome.trim();
                this.exibeModalConfirmacao = false;
                this.exibeModal = true;
            }

            this.funcaoConfirmar = (pessoa: Pessoa) => {
                this.atualizar(pessoa, pessoa.idPessoa)
                this.exibeModalConfirmacao = false;
            }
        },

        criar(pessoa: Pessoa, criarOutro: boolean) {
            customEvents.exibeCarregando('Criando pessoa...');

            return pessoaService.criar(pessoa)
                .then((novaPessoa) => {
                    this.pessoa = new Pessoa();

                    customEvents.exibeCarregando('Verificando anexos...');

                    pessoaAnexoService.processarAnexo(pessoa.anexos, novaPessoa.idPessoaApi, this.usuarioAutenticado.chaveUsuario)
                        .then(() => {
                            this.pessoa = new Pessoa();

                            pessoa.estende(novaPessoa);

                            document.dispatchEvent( 
                                new CustomEvent(mfPessoaEvento.crud.PESSOA_CRIADA, {
                                    detail: {
                                        pessoa: pessoa,
                                        tokenEvento: this.tokenEvento
                                    }
                                })
                            );

                            if (!criarOutro) {
                                this.fechaModal();
                            }
                            else {
                                enderecoStore.dispatch('limparEnderecoStore');
                                enderecoStore.dispatch('setEnderecos', this.pessoa.enderecos);
                                this.cloneEnderecos = cloneDeep(this.pessoa.enderecos);
                            }
                        })
                        .catch((erro) => {
                            const MSG_DEFAULT = 'Houve um problema ao processar os anexos.';
                            customEvents.exibeAlerta({msg:ErrosBackend.parseErrors(erro) || MSG_DEFAULT});
                        })
                        .finally(() => {
                            customEvents.escondeCarregando();
                        });
                })
                .catch((erro) => {
                    let MSG_DEFAULT = '';
                    
                    switch (erro.response.data.erros[0].erroOriginal.mensagem) {
                        case 'O conteúdo do campo email não é um e-mail válido.':
                            MSG_DEFAULT= erro.response.data.erros[0].erroOriginal.mensagem;
                            break;
                        default:
                        MSG_DEFAULT='Houve um problema no momento da criação da pessoa.';
                    }
                    customEvents.exibeAlerta({msg:ErrosBackend.parseErrors(erro) || MSG_DEFAULT});
                })
                .finally(() => {
                    customEvents.escondeCarregando();
                });
        },

        atualizar(obj: Pessoa, id: number) {
            customEvents.exibeCarregando("Atualizando registro de pessoa...");
            pessoaService.atualizar(obj, id)
                .then((objAtualizado) => {

                    setTimeout(() => {
                        customEvents.exibeCarregando("Verificando anexos...");

                        Promise.all([
                            pessoaAnexoService.removerAnexosPessoa(this.anexosExcluidos),
                            pessoaAnexoService.processarAnexo(obj.anexos, obj.idPessoaApi, this.usuarioAutenticado.chaveUsuario)
                        ])
                        .then(() => {

                            Object.assign(obj, objAtualizado);

                            this.fechaModal();

                            document.dispatchEvent(
                                new CustomEvent(mfPessoaEvento.crud.PESSOA_ATUALIZADA, {
                                    detail: {
                                        pessoa: obj,
                                        tokenEvento: this.tokenEvento
                                    }
                                })
                            );
                            this.pessoa = new Pessoa();
                        })
                        .catch((erro) => {
                            const MSG_DEFAULT = 'Houve um problema ao processar os anexos.';
                            customEvents.exibeAlerta({msg:ErrosBackend.parseErrors(erro) || MSG_DEFAULT});
                        })
                        .finally(() => {
                            customEvents.escondeCarregando();
                        });
                    }, 200);
                })
                .catch((erro) => {
                    var MSG_DEFAULT = 'Houve um problema no momento da edição da pessoa.';
                    customEvents.exibeAlerta({msg:ErrosBackend.parseErrors(erro) || MSG_DEFAULT});
                })
                .finally(() => {
                    customEvents.escondeCarregando();
                });
        },

        verificaRemocaoTipoPessoa(tipoPessoaRemovido:TipoPessoa) {

            const {idPessoa} = this.pessoa;
            const {codigoTipoPessoa} = tipoPessoaRemovido;
            const tiposPessoaRestantes = this._obterTiposPessoaRestantes(codigoTipoPessoa);

            const existeTipoMesmoCodigo = (
                this.pessoa.tiposPessoa.length > 1 && 
                this.pessoa.tiposPessoa.some(tipo => tipo.codigoTipoPessoa === enumTipoPessoa.PERSONALIZADO.codigoTipoPessoa)
            );

            if (!idPessoa || existeTipoMesmoCodigo) {
                return this.onChangeTiposPessoa();
            }

            customEvents.exibeCarregando("Validando remoção do tipo da pessoa...");

            pessoaService.verificaAlteracaoTipo(idPessoa, codigoTipoPessoa, tiposPessoaRestantes)
                .catch((erro) => {
                    var MSG_DEFAULT = 'Houve um problema ao tentar remover o tipo da pessoa.';
                    customEvents.exibeAlerta({msg:ErrosBackend.parseErrors(erro) || MSG_DEFAULT});

                    this.pessoa.tiposPessoa.push(tipoPessoaRemovido);
                })
                .finally(() => {
                    customEvents.escondeCarregando();
                    this.onChangeTiposPessoa();
                });

        },

        atulizaCampoComentario(texto:string) {
            this.textoParaComentario = texto;
            if (!texto.length) {
                this.temPendenciasComentario = false;
            }
        },

        onChangeTiposPessoa () {
            this.pessoa.removerPendencia('listaTpPessoa');
            this.atualizarCategoriaeCusto();
        },        

        _obterTiposPessoaRestantes(codigoTipoPessoa:number) {
            const {tiposPessoa} = this.pessoa;

            if (!tiposPessoa) {
                return undefined;
            }

            const filterTiposPessoa = tiposPessoa.filter((tp) => {
                return tp.codigoTipoPessoa !== codigoTipoPessoa;
            });

            const tiposRestantes = filterTiposPessoa.map((tp) => tp.codigoTipoPessoa);

            return tiposRestantes.length ? tiposRestantes : undefined;
        },

        temCategoriasECustos() {
            return this.temCategoriasReceitas() && this.temCategoriasDespesas() && this.temCentrosDeCusto();
        },

        temCategoriasReceitas() {
            return this.categoriasReceitas && this.categoriasReceitas.length;
        },

        temCategoriasDespesas() {
            return this.categoriasDespesas && this.categoriasDespesas.length;
        },

        temCentrosDeCusto() {
            return this.centrosDeCusto && this.centrosDeCusto.length;
        },

        atualizarCategoriaeCusto() {
            if (this.pessoa.atualizaSelectCadastroReceita()) {
                this.pessoa.categoriaEntradaPadrao = undefined;
            }
            if (this.pessoa.atualizaSelectCadastroDespesa()) {
                this.pessoa.categoriaSaidaPadrao = undefined;
            }
            if (this.pessoa.atualizaSelectCentroDeCusto()) {
                this.pessoa.centroCustoPadrao = undefined;
            }
        },

        getIconeAlerta(exibeIcone: boolean) {
            return exibeIcone ? "fas fa-exclamation-triangle has-text-danger" : undefined;
        },

        getDescricaoEnum(opt:number, array: Array<{enum:number, descricao:string}>) {
            const tipoContribuinte = array.find(x => x.enum == opt);
            return tipoContribuinte ? tipoContribuinte.descricao : '';
        },

        exibeInfoPessoaDesabilitada() {
            customEvents.exibeAlerta({
                msg: TEMPLATE_INFO_PESSOA_DESABILITADA,
                tipo: 'info'
            });
        },
        fecharModalTipoPessoa (){
            this.modalTipoPessoaOpen = false;
            if(this.componenteTipoPessoaPorEvento){
                this.componenteTipoPessoaPorEvento = false;
                return
            }
            this.exibeModal = true;
        },
        atualizarListaTiposPessoa(objTipoPessoa: TipoPessoa) {
            this.pessoa.removerPendencia('listaTpPessoa');
            const tipoPessoaNovo = objTipoPessoa.tipoPessoa;
            const tipoEvento = objTipoPessoa.tipoEvento;
            if(this.componenteTipoPessoaPorEvento && tipoEvento) {
                document.dispatchEvent(                    
                    new CustomEvent(tipoEvento, {
                        detail: {
                            tipoPessoa: tipoPessoaNovo,
                            tokenEvento: this.tokenEvento,
                        },
                    })
                );
                return
            }
            this.tiposPessoa.unshift(tipoPessoaNovo)
            this.pessoa.tiposPessoa.push(tipoPessoaNovo)
        },
        // TODO - Implementar overflow

        // acionar adicionando  @scroll="onScroll" na div
        /* onScroll(e:Event) {
            const el = e.currentTarget as Element;
            this.setClasses(el);
        },

        setClasses(el:Element) {
            const isScrollable = el.scrollHeight > el.clientHeight;

            // Se elemento não é rolável remove todas as classes
            if (!isScrollable) {
                el.classList.remove('is-bottom-overflowing', 'is-top-overflowing');
                return;
            }

            // Verifica qual direção está ocorrendo overflow (pode ser as duas)
            const isScrolledToBottom = el.scrollHeight <= el.clientHeight + el.scrollTop;
            const isScroledlToTop = el.scrollTop === 0;
            el.classList.toggle('is-bottom-overflowing', !isScrolledToBottom);
            el.classList.toggle('is-top-overflowing', !isScroledlToTop);
        },

        atualizaContainerTelefones() {
            setTimeout(() => {
                const containerTelefone = document.querySelector('#container-telefones');
                if (containerTelefone) {
                    this.setClasses(containerTelefone);
                }
            });
        }, */

        atualizaStatusPessoa(status:boolean) {
            this.pessoa.status = status ? 1 : 0;
        },

        atualizaTelefonePrincipal(telefoneMarcado:Telefone) {
            for (const telefone of this.pessoa.telefones) {
                if (telefone === telefoneMarcado) {
                    telefone.principal = true;
                }
                else {
                    telefone.principal = false;
                }
            }
        },

        adicionarTelefoneCnpj(telefone:string) {            
            if (telefone) {
                for (let i = 0; i < this.pessoa.telefones.length; ++i) {
                    if (telefone === this.pessoa.telefones[i].numero) {
                        return;
                    }
                }

                this.pessoa.telefones.push(new Telefone({numero: telefone}));

                if (this.pessoa.telefones.length === 1) {
                    this.pessoa.telefones[0].principal = true;
                }
            }
        },

        adicionaEmail() {
            this.pessoa.emails.push(new Email())
            if (this.pessoa.emails.length === 1) {
                this.pessoa.emails[0].principal = true;
            }
        },

        removeEmail(index:number, email:Email) {
            this.pessoa.emails.splice(index, 1);
            if (email.principal && this.pessoa.emails.length) {
                this.pessoa.emails[0].principal = true;
            }
        },

        atualizaEmailPrincipal(emailMarcado:Email) {
            for (const email of this.pessoa.emails) {
                if (email === emailMarcado) {
                    email.principal = true;
                }
                else {
                    email.principal = false;
                }
            }
        },

        adicionarTelefone() {
            this.pessoa.telefones.push(new Telefone());
            if (this.pessoa.telefones.length === 1) {
                this.pessoa.telefones[0].principal = true;
            }
            // this.atualizaContainerTelefones();
        },

        removerTelefone(index:number, telefone:Telefone) {
            this.pessoa.telefones.splice(index, 1);
            this.pessoa.removePendenciasContato(telefone.propPossuiPendencia('numero') || !!telefone.numeroRepetido)
            if (telefone.principal && this.pessoa.telefones.length) {
                this.pessoa.telefones[0].principal = true;
            }
            // this.atualizaContainerTelefones();
        },

        mascaraCpfCnpj() {
            if (this.pessoa.cpfCnpj && this.pessoa.cpfCnpj.replace(/\D/g, '').length > 11) {
                return '##.###.###/####-##';
            }
            return '###.###.###-##';
        },

        mascaraTelefone(numero: string) {
            let somenteNumero = numero.replace(/\D/g, '');

            if (somenteNumero.startsWith('0800')) {
                return '#### ### ####'
            }

            if (numero && somenteNumero.length > 10) {
                return '(##) #####-####';
            }

            return '(##) ####-####';
        },

        adicionarVeiculo() {
            this.pessoa.veiculosTransportadora.push(new Veiculo())
        },

        removerVeiculo(index:number, veiculoTransportadora: Veiculo) {
            this.pessoa.veiculosTransportadora.splice(index, 1);
            this.pessoa.removePendenciasVeiculos(veiculoTransportadora.propPossuiPendencia('placa') || !!veiculoTransportadora.placaRepetida)
        },

        adicionarEnderecoPorCnpj(endereco: Endereco) {
            enderecoStore.dispatch('limparEnderecoStore');

            endereco.identificacao = this.tiposEndereco.PRINCIPAL;
            this.pessoa.enderecos = [];
            this.pessoa.enderecos.push(new Endereco(endereco));

            enderecoStore.dispatch('setEnderecos', this.pessoa.enderecos);
        },

        abreModal(novaPessoa:boolean) {
            this.exibeModal = true;
            ModalHtml.addClass();

            this.$nextTick(() => {
                enderecoStore.dispatch('setEnderecos', this.pessoa.enderecos);
                enderecoStore.dispatch('ordenar');
                this.cloneEnderecos = cloneDeep(this.pessoa.enderecos);
            });        
            if (novaPessoa) {
                this.$refs.tourVirutal?.verificaExibicaoTour();  
            }
        },

        async fechaModal(cancelar = false) {
            if (cancelar && !this.pessoa.isNew() && !isEqual(this.cloneEnderecos, this.enderecos)) {
                const message = `Algumas informações do <strong>Endereço</strong> foram alteradas  
                    no cadastro. Esses dados serão perdidos caso não realize a <strong>gravação</strong>. 
                    Deseja realmente cancelar?`;

                const result = await this.$confirm({
                    message: message,
                    tipo:'warning'
                });
                
                if (!result) return;
            }

            this.exibeModal = false;
            ModalHtml.removeClass();

            if (cancelar){
                document.dispatchEvent( 
                    new CustomEvent(mfPessoaEvento.modal.MODAL_PESSOA_ESPECIFICA_FOI_FECHADO, {
                        detail: {
                            tokenEvento: this.tokenEvento
                        }
                    })
                );
            }
        },

        abreModalConfirmacao(titulo:string, msg:string){
            this.tituloModalConfirmacao = titulo
            this.msgModalConfirmacao = msg
            this.exibeModalConfirmacao = true
        },

        formataCpfCnpj(cpfCnpj:string){
            if (cpfCnpj.length === 11) {
                return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            } else if (cpfCnpj.length === 14) {
                return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _agrupaPor(array:any[], key:string) {
            let resultado = [];
            let agrupado = array.reduce((r, a) => {
                r[a[key]] = [...r[a[key]] || [], a];
                return r;
            }, {})

            for (const key in agrupado) {
                resultado.push({
                    grupo: key,
                    itens: agrupado[key]
                })
            }
            return resultado;
        },

        _ordernaTelefones(telefones:Telefone[]) {
            telefones.sort(this._comparaTelefones.bind(this));
        },

        _comparaTelefones(a:Telefone, b:Telefone) {
            return Number(b.principal) - Number(a.principal) || a.tipo - b.tipo || this._comparaContato(a.contato, b.contato) || a.idTelefoneApi.localeCompare(b.idTelefoneApi);
        },

        _comparaContato(contatoA:string, contatoB:string) {
            if(contatoA === "") {
                return 1;
            } else if(contatoB === "") {
                return -1;
            } else {
                return contatoA < contatoB ? -1 : contatoA > contatoB? 1 : 0;
            }
        },

        _ordernaVeiculos(veiculos:Veiculo[]) {
            veiculos.sort(function (a, b) {
                const idVeiculoA = a.idVeiculo || 0;
                const idVeiculoB = b.idVeiculo || 0;
                return idVeiculoA - idVeiculoB;
            });
        },

        async _inicializaTiposPessoa() {
            const tiposPessoa = await pessoaService.obterTiposPessoa(1);

            let _tiposPessoas:enumTiposPessoaType = tiposPessoa ?? enumTipoPessoa;
            this.tiposPessoa = []

            if (!this.possuiAcessoMovimento) {
                delete _tiposPessoas.TRANSPORTADORA;
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(_tiposPessoas).forEach(([key, value]) => {
                if (!this.tiposPessoaPermitidos.length || this.tiposPessoaPermitidos.includes(value.codigoTipoPessoa)) {
                    this.tiposPessoa.push(value)
                }
            });

            if (this.tiposPessoa.length === 1) {
                this.pessoa.tiposPessoa.push(this.tiposPessoa[0]);
                this.atualizarCategoriaeCusto();
            }
        },

        selecionaAba(index) {
            this.$refs.abas?.select(index);
            const abas = document.querySelector('mf-erpforme-pessoa')?.shadowRoot?.querySelector('#koopon-modal-pessoa-especifica .tabs') as HTMLElement;
            if (abas) {
                const aba = abas.querySelectorAll('ul>li')[index] as HTMLElement;
    
                let navWidth = abas.clientWidth || 0;
                let tabWidth = aba?.clientWidth || 0;
                let offsetLeft = aba?.offsetLeft || 0;
                let offsetRight = navWidth - tabWidth - offsetLeft;
    
                offsetLeft = offsetLeft < 0 ? 0 : offsetLeft;
                offsetRight = offsetRight < 0 ? 0 : offsetRight;
    
                let middle = (offsetLeft + offsetRight) / 2;
                const scroll = Math.abs(offsetLeft - middle);
                if (offsetLeft > offsetRight) {
                    abas.scrollLeft += scroll
                }
                else {
                    abas.scrollLeft -= scroll
                }
            }
        },

        _removePassoTourSemAcessoFuncionalidade(target: string) {
            this.tourPessoa.passos = this.tourPessoa.passos.filter(function( obj ) {
                return obj.target !== target;
            });
        },

        _inicializar(e: unknown) {
            const customEvent = e as CustomEvent;
            const passaporteProdutosAcessos = new PassaporteProdutosAcessos();

            this.pessoa = new Pessoa();
            this.anexoJaExiste = false;
            this.originalCpfCnpj = '';
            this.cpfCnpjJaExiste = false;
            this.carregandoEndereco = false;
            this.permiteCriacaoDeOutraPessoa = true;
            this.permiteEditarTipos = true;
            this.tiposPessoaPermitidos = [];
            this.avisoTiposDePessoaPermitidos = '';
            this.anexosExcluidos = [];
            this.textoParaComentario = '';
            this.temPendenciasComentario = false;
            this.possuiAcessoMovimento = passaporteProdutosAcessos.temAcessoProduto(acessosSistema.movimento.CHAVE_ACESSO);
            this.podeIncluirAnotacao = passaporteProdutosAcessos.temAcessoFuncionalidade(acessosSistema.pessoa.INCLUIR_ANOTACAO_PESSOA);
            this.podeIncluirAnexo = passaporteProdutosAcessos.temAcessoFuncionalidade(acessosSistema.pessoa.INCLUIR_ANEXO_PESSOA);
            this.keyAbas++;
            this.tokenEvento = '';
            this.cloneEnderecos = [];
            this.tourPessoa = structuredClone(pessoaEspecificaTour);

            if (!this.podeIncluirAnotacao) {
                this._removePassoTourSemAcessoFuncionalidade('#koopon-modal-pessoa-especifica .passo-comentarios');
            }

            if (!this.podeIncluirAnexo) {
                this._removePassoTourSemAcessoFuncionalidade('#koopon-modal-pessoa-especifica .passo-anexos');
            }

            if (customEvent.detail) {
                const {pessoa,
                       categoriasECustos,
                       permiteCriacaoDeOutraEntidade,
                       configTipoPessoa,
                       permiteEditarTipos,
                       tokenEvento
                } = customEvent.detail;

                if (pessoa) {
                    this.pessoa.idPessoa = pessoa.idPessoa;
                }

                if (categoriasECustos) {
                    this.centrosDeCusto = categoriasECustos.centrosDeCusto;
                    this.categoriasDespesas = categoriasECustos.categoriasDespesas ? this._agrupaPor(categoriasECustos.categoriasDespesas, 'nomeAgrupamento') : undefined;
                    this.categoriasReceitas = categoriasECustos.categoriasReceitas ? this._agrupaPor(categoriasECustos.categoriasReceitas, 'nomeAgrupamento') : undefined;
                }

                if (permiteCriacaoDeOutraEntidade === false) {
                    this.criarOutraPessoa = false;
                    this.permiteCriacaoDeOutraPessoa = false;
                }

                if (typeof configTipoPessoa === 'object') {
                    this.tiposPessoaPermitidos = configTipoPessoa.tiposPermitidos;
                    this.avisoTiposDePessoaPermitidos = configTipoPessoa.label;
                }

                if (!this.temCategoriasECustos()) {
                    this._removePassoTourSemAcessoFuncionalidade('#koopon-modal-pessoa-especifica .passo-7');
                    this._removePassoTourSemAcessoFuncionalidade('#koopon-modal-pessoa-especifica .passo-8');
                }

                this.permiteEditarTipos = permiteEditarTipos !== false;

                this.tokenEvento = tokenEvento;
            }

            this._inicializaTiposPessoa();

            if (!this.pessoa.idPessoa) {
                return this.abreModal(true); 
            }

            customEvents.exibeCarregando('Carregando pessoa...');

            pessoaService.obterPorId(this.pessoa.idPessoa).then((pessoa) => {
                this.pessoa = pessoa;
                this.pessoaStatus = !!this.pessoa.status;
                this.originalCpfCnpj = this.pessoa.cpfCnpj;
                this.originalNome = this.pessoa.nome;
                this._ordernaTelefones(this.pessoa.telefones);
                this._ordernaVeiculos(this.pessoa.veiculosTransportadora);
                this.abreModal();
            }).catch((erro) => {
                var MSG_DEFAULT = 'Houve um problema no momento de buscar a pessoa escolhida.';
                customEvents.exibeAlerta({ msg:ErrosBackend.parseErrors(erro) || MSG_DEFAULT });
            }).finally(() => {
                customEvents.escondeCarregando();
            });
        },
        _inicializarModalTipoPessoa(e: any) {
            this.idTipoPessoa = 0;
            if(e && e.detail && e.detail.idTipoPessoa) {
                this.idTipoPessoa = e.detail.idTipoPessoa;
            }
            this.modalTipoPessoaOpen = true;
            this.componenteTipoPessoaPorEvento = true;
    
        },
        _exibirTourNovamente() {
            this.$refs.tourVirutal?.exibirTourNovamente();
        }
    },

    mounted() {
        document.addEventListener(mfPessoaEvento.modal.ABRE_MODAL_PESSOA_ESPECIFICA, this._inicializar, false);
        document.addEventListener(mfPessoaEvento.modal.ABRE_MODAL_TIPO_PESSOA, this._inicializarModalTipoPessoa, false);
        document.addEventListener(mfPessoaEvento.tour.EXIBE_TOUR, this._exibirTourNovamente, false);
        ubus.on(mfPessoaEvento.mock.ENDERECO_PREENCHE_DADOS, () => { this.selecionaAba(0) });
        ubus.on(mfPessoaEvento.mock.CONTATO_PREENCHE_DADOS, () => { this.selecionaAba(1) });
        ubus.on(mfPessoaEvento.mock.COMENTARIOS_PREENCHE_DADOS, () => { this.selecionaAba(2) });
        ubus.on(mfPessoaEvento.mock.ANEXOS_PREENCHE_DADOS, () => { this.selecionaAba(3) });
    },

    beforeDestroy() {
        document.removeEventListener(mfPessoaEvento.modal.ABRE_MODAL_PESSOA_ESPECIFICA, this._inicializar);
        document.removeEventListener(mfPessoaEvento.modal.ABRE_MODAL_TIPO_PESSOA, this._inicializarModalTipoPessoa);
        document.removeEventListener(mfPessoaEvento.tour.EXIBE_TOUR, this._exibirTourNovamente);
    }
}
</script>

<style lang="scss">
#koopon-modal-pessoa-especifica {
    font-family: 'Roboto', sans-serif;

    .modal-card-body {
        z-index: inherit;
    }

    // TODO - remover o estilo abaixo forçado por conta de erro gerado no build do v-calendar
    .mf-pessoa-data-nascimento {
        .input {
            background: transparent;
            z-index: 1;
        }
        span.fa-calendar-alt {
            z-index: 0;
        }
    }

    .sd-tour {
        &__target {
            &--highlighted {
                &.adiciona-padding {
                    &.passo-13 {
                        margin-top: -10px;
                        margin-bottom: -10px;
                    }
                }
            }
        }
    }

    .multiselect__tag {
        margin-bottom:0px !important;
    }

    .vue-bulma-tabs .tab-pane.sd-tour__target--highlighted {
        padding: 10px;
    }
}

#telefones-cabecalho-labels, 
#emails-cabecalho-labels,
#veiculos-cabecalho-labels {
    padding: 0px 5px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.25rem;
}

#container-telefones,
#container-emails,
#container-veiculos {

    // TODO - Implementar overflow (adicionar classe 'custom-scrollbar' na div) - Será preciso solucionar a abertura do vue-multiselect (https://github.com/shentao/vue-multiselect/issues/723)
    /* max-height: 180px;
    overflow-y: scroll;
    overflow-x: clip; */

    .container-telefone,
    .container-email,
    .container-veiculo  {
        border: 1px solid #e3e3e8;
        padding: 0px 5px;
        position: relative;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;

        .checkbox-principal {
            margin: 9px 0;
        }

        .icone-excluir-telefone,
        .icone-excluir-email,
        .icone-excluir-veiculo {
            position: absolute;
            top: 7px;
            right: 7px;
            font-size: 16px;
            color: lighten(#697176, 15%);
            cursor: pointer;
            &:hover {
                color: #697176;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #container-telefones,
    #container-emails,
    #container-veiculos {
        // TODO - Implementar overflow
        // max-height: 450px;
        .container-telefone,
        .container-email,
        .container-veiculo {
            padding: 10px;

            .checkbox-principal {
                margin-left: 9px;
            }
        }
    }
}

</style>