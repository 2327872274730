import { render, staticRenderFns } from "./pessoa_especifica.vue?vue&type=template&id=64145452"
import script from "./pessoa_especifica.vue?vue&type=script&lang=ts"
export * from "./pessoa_especifica.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./pessoa_especifica.vue?vue&type=style&index=0&id=64145452&prod&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports