<template>
    <div id="mf-erpforme-pessoa-anexos-container">

        <div class="file">
            <label class="file-label">
                <input class="file-input" 
                    id="mf-pessoa-erpforme-especifica-input-adicionar-anexo"
                    type="file" 
                    name="resume" 
                    @change="(event) => adicionarAnexo(event)" :value="arquivo">
                <span class="file-cta">
                    <span class="file-label">
                        Adicionar Anexo (Máximo de 5 MB)
                    </span>
                </span>
            </label>
        </div>

        <div class="columns">
            <div class="column alt-espacamento-bottom">
                <p class="has-text-danger" v-show="msgErroArquivo">{{msgErroArquivo}}</p>
            </div>
        </div>

        <div class="listagem-anexos-container">
            <div class="anexos-container" v-for="(anexo, index) in anexos" :key="index" v-show="anexos.length">
                <div class="is-flex">
                    <div>
                        <a class="anexo is-clickable" v-show="anexo.idAnexo" :href="anexo.urlDownload" target="_blank">{{ anexo.nome }}</a>
                        <p class="anexo" v-show="!anexo.idAnexo">{{anexo.nome}}</p>
                        <p class="has-text-grey mt-2">
                            Data de Inclusão:
                            <span>{{anexo.dataFormatada()}}</span>
                        </p>
                    </div>
                    <span class="icone-excluir-anexo" @click="excluirAnexo(anexo.idAnexo, anexo.selecionadoEm, anexosExcluidos)" title="Remover anexo">
                        <i class="fa fa-times"></i><span class="sr-only">Excluir</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { Anexo } from '@/models/anexo_model'

const TEMPO_AVISO_MSG_ERRO = 10000;
const TAMANHO_MAX = 5119500; //5MB

export default {
    name: 'pessoaAnexos',
    props: {
        value: {
            type: Array as PropType<Anexo[]>,
            required: true
        }
    },
    data: () => ({
        arquivo: undefined,
        anexos: [] as Anexo[],
        anexoJaExiste: false,
        anexosExcluidos: [],
        msgErroArquivo: ''
    }),

    mounted() {
        this.anexos = [...this.value]
    },

    methods: {

        adicionarAnexo(e:Event) {
            const target = e.target as HTMLInputElement;
            const files = target.files;
            if (!files || !files[0]) {
                return;
            }
            const _anexo = new Anexo();

            _anexo.estendeArquivo(files[0]);

            this.anexoJaExiste = false;

            for (let i = 0; i < this.anexos.length; i++) {
                if (_anexo.nome === this.anexos[i].nome) {
                    return this._exibeMsgErroArquivo('O arquivo já existe.');
                }
            }

            if (_anexo.tamanho && _anexo.tamanho > TAMANHO_MAX) {
                return this._exibeMsgErroArquivo("O arquivo informado ultrapassou o limite de tamanho de 5MB.");
            }

            this.anexos.unshift(_anexo);
            this.arquivo = undefined;
        },

        excluirAnexo(idAnexo:string, selecionadoEm:Date, anexosExcluidos:string[]) {
            for (var i = 0, _tamanho = this.anexos.length; i < _tamanho; i++) {
                var _an = this.anexos[i];

                if ((_an.selecionadoEm && (_an.selecionadoEm === selecionadoEm)) || (_an.idAnexo && (_an.idAnexo === idAnexo))) {
                    this.anexos.splice(i, 1);
                    anexosExcluidos.push(_an.idAnexo);
                    break;
                }
            }
        },

        _exibeMsgErroArquivo(msg:string) {
            this.msgErroArquivo = msg;

            setTimeout(() => {
                this.msgErroArquivo = '';
            }, TEMPO_AVISO_MSG_ERRO);
        }
    },
    watch: {
        anexos() {
            this.$emit('update', this.anexos)
        },
        anexosExcluidos() {
            this.$emit('atualizaAnexosExcluidos', this.anexosExcluidos)
        }
    }
};
</script>

<style lang="scss">
$grey: #697176;
$grey-1: #e3e3e8;
.anexos-container {
  border: 1px solid $grey-1;
  padding: 5px;
  margin-top: 10px;
  position: relative
}

.anexo {
  word-break: break-word
}

.icone-excluir-anexo {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 16px;
  color: lighten($grey, 15%);
  cursor: pointer;
}
.icone-excluir-anexo:hover {
  color: $grey;
}
</style>

