<template>
  <transition name="fade">
    <div id="koopon-modal-tipo-pessoa" class="modal modal-no-overflow" :class="{'is-active':showModal}">
    <div class="modal-content dialog">
      <header class="modal-card-head alt-cor-principal">
        <h3 class="modal-card-title">
          {{
            tipoPessoa.isNew() ? "Novo Tipo de Pessoa" : "Editar Tipo de Pessoa"
          }}
        </h3>
        <button @click="fecharModal()" class="close" aria-label="close">
          <i class="fa fa-times"></i>
        </button>
      </header>
      <section class="modal-card-body">
        <form class="form-group">
          <div class="columns is-flex-wrap-wrap">
            <div class="column">
              <label>Descrição</label>
              <input
                :class="{
                  'is-danger': tipoPessoa.propPossuiPendencia('descricao'),
                }"
                type="text"
                class="input"
                maxlength="50"
                @keypress="removerHintPendencia"
                v-model.trim="tipoPessoa.descricao"
                @keydown.enter.prevent
              />
              <small
                class="text-muted"
                v-show="!tipoPessoa.propPossuiPendencia('descricao')"
                >Máximo de 50 caracteres</small
              >
              <small
                class="text-danger"
                v-show="tipoPessoa.propPossuiPendencia('descricao')"
                v-html="tipoPessoa.obterMensagemPendencia('descricao')"
              ></small>
            </div>
          </div>
          <label class="alt-espacamento-top">
            <input
              id="mf-pessoa-especifico-checkbox-permiteLogin"
              type="checkbox"
              v-model="tipoPessoa.permiteLogin"
            />
            <span class="alt-checkbox"></span>
            <label
              >Este tipo de pessoa também será um usuário dos sistemas
              complementares</label
            >
            <span
              class="tooltip-top"
              @click="exibirMensagemSistemasComplementares()"
            >
              <i
                class="fa fa-info-circle"
                style="color: #777; margin-left: 5px; font-size: 16px"
              ></i>
            </span>
          </label>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div
          class="mr-2"
          v-show="tipoPessoa.isNew() && permiteCriacaoDeOutraPessoa"
        >
          <label class="is-clickable has-text-left mb-0">
            <input
              type="checkbox"
              v-model="criarOutroTipoPessoa"
              id="mf-pessoa-erpforme-especifica-input-criar-outro-tipo-pessoa"
            />
            <span class="alt-checkbox"></span>
            <span class="alt-checkbox-label">Criar outro</span>
          </label>
        </div>
        <button
          @click="gravar(tipoPessoa)"
          id="mf-pessoa-erpforme-especifica-input-botao-gravar"
          class="button is-primary"          
        >
          Gravar
        </button>
        <button
          @click="fecharModal()"
          id="mf-pessoa-erpforme-especifica-input-botao-fechar-modal"
          class="button is-default"
        >
          Cancelar
        </button>
      </footer>
    </div>
  </div>
  </transition>
</template>

<script lang="ts">
import { mfPessoaEvento, kooponPessoaMensagensRetorno} from '@/constants';
import { TipoPessoa } from "../../models/tipo_pessoa_model";
import tipoPessoaService from "../../services/tipo_pessoa_service";
import customEvents from "../../services/custom_events";
import ErrosBackend from "../../libs/comum/helpers/comum_error_parse";

export default {
  name: "kooponPessoaTipoPessoa",

  components: {},

  data: () => ({
    tipoPessoa: new TipoPessoa(),
    permiteCriacaoDeOutraPessoa: true,
    criarOutroTipoPessoa: false,
    avisoTiposDePessoaPermitidos: "permitido",
    tokenEvento: '',
    descricao: '',
  }),
  props: {
    showModal: {type: Boolean, default: true},
    idTipoPessoa: {type: Number}
  },
  methods: {
    removerHintPendencia() {
      this.tipoPessoa.removerPendencia("descricao");
    },
    gravar(tipoPessoa: TipoPessoa, id: number) {
      if (tipoPessoa.validar()) {
        if (tipoPessoa.isNew()) {
          return this.criar(tipoPessoa);
        }
        return this.atualizar(tipoPessoa, this.idTipoPessoa);
      }
    },
    async criar(obj: TipoPessoa) {
      customEvents.exibeCarregando();
      try {
        const objNovo = await tipoPessoaService.criar(obj);
        this.tipoPessoa = new TipoPessoa();
        const parametros = {tipoPessoa: objNovo, tipoEvento: mfPessoaEvento.crud.TIPO_PESSOA_CRIADA}
        this.$emit("tipoPessoaCriado", parametros);
        if (!this.criarOutroTipoPessoa) {
          this.fecharModal();
        }
      } catch (error) {
        const MSG_DEFAULT =
          "Houve um problema no momento da criação do tipo de pessoa.";
        customEvents.exibeAlerta({
          msg: ErrosBackend.parseErrors(error) || MSG_DEFAULT,
        });
      } finally {
        customEvents.escondeCarregando();
      }
    },
    async atualizar(obj: TipoPessoa, id: number) {
      customEvents.exibeCarregando();
      try {
        const objAtualizado = await tipoPessoaService.atualizar(obj, id);
        const parametros = {tipoPessoa: objAtualizado, tipoEvento: mfPessoaEvento.crud.TIPO_PESSOA_ATUALIZADA}
        this.$emit("tipoPessoaCriado", parametros);
        this.fecharModal();
      } catch (error) {
        const MSG_DEFAULT ="Houve um problema no momento de editar o tipo de pessoa.";
        customEvents.exibeAlerta({
          msg: ErrosBackend.parseErrors(error) || MSG_DEFAULT,
        });
      } finally {
        customEvents.escondeCarregando();
      }
    },
    buscarPorId (id: number){
      customEvents.exibeCarregando();
      tipoPessoaService.buscarTipoPessoaPorId(id)
      .then((obj)=>{
        return this.tipoPessoa = obj;
      }).catch ((error) => {
        const MSG_DEFAULT = "Houve um problema no momento de buscar o tipo de pessoa escolhida.";
        customEvents.exibeAlerta({
          msg: ErrosBackend.parseErrors(error) || MSG_DEFAULT,
        });
      }).finally (()=>{
        customEvents.escondeCarregando();
      })
    },
    fecharModal() {
      this.tipoPessoa = new TipoPessoa();
      this.$emit("fecharModalTipoPessoa");
    },
    exibirMensagemSistemasComplementares() {
      this.$alert({
        tipo: "info",
        message: kooponPessoaMensagensRetorno.INFO_SISTEMAS_COMPLEMENTARES,
      });
    },
  },
  mounted() {
    if(this.idTipoPessoa){
      this.buscarPorId(this.idTipoPessoa)
    }
  },
};
</script>
