export default class ModalHtml {

    static addClass() {
        const html = document.getElementsByTagName( 'html' )[0];
        html.classList.add('is-clipped');
        // Caso tenha algum modal já aberto é necessário um timeout pois remove a classe antes de adicionar novamente
        const TIMEOUT = document.body.classList.contains("modal-open") ?  500 : 0
        setTimeout(() => {
            document.body.classList.add("modal-open");
        }, TIMEOUT);
    }

    static removeClass() {
        const html = document.getElementsByTagName( 'html' )[0];
        html.classList.remove('is-clipped');
        document.body.classList.remove("modal-open");

    }
}