
export class PendenciaFormulario {
    public prop:string|undefined = '';
    public mensagem:string|undefined = '';
    public mensagemHtml:string|undefined = undefined;
    public tipo:string = PendenciaFormulario.tipos().AGRUPADA;
  
    constructor(p?:Partial<PendenciaFormulario>) {
        Object.assign(this, p);
        this._parseTipo();
    }

    static tipos() {
        return {
            AGRUPADA: 'agrupada',
            INDIVIDUAL: 'individual'
        };
    }

    _parseTipo() {
        if (this.tipo !== PendenciaFormulario.tipos().INDIVIDUAL && this.tipo !== PendenciaFormulario.tipos().AGRUPADA) {
            this.tipo = PendenciaFormulario.tipos().INDIVIDUAL;
        }
    }
  
  }
