<template>
<transition name="fade">
  <div id="koopon-comum-alerta-generico" class="is-active modal" v-if="notification">
    <div class="modal-background"></div>
    <div class="modal-content modal-md" :class="tipo">
      
      <header class="modal-card-head">
        <div class="koopon-alerta-generico-icone" :class="tipo">
          <i class="fa fa-exclamation-triangle" v-show="tipo === 'warning'"></i>
          <i class="fa fa-info" v-show="tipo === 'info'"></i>
          <i class="fa fa-times" v-show="tipo === 'danger'"></i>
          <i class="fa fa-check" v-show="tipo === 'success'"></i>
        </div>
        <h3 class="modal-card-title" v-html="title"></h3>
        <button @click="resolve()" class="close" aria-label="close">
          <i class="fa fa-times"></i>
        </button>
      </header>

      <section class="modal-card-body">
        <p v-html="message"></p>

        <div v-if="!!erroOriginal" class="mt-2">
          <a @click="exibeErroOriginal = !exibeErroOriginal" role="button" aria-expanded="false">
            {{exibeErroOriginal ? 'Menos ' : 'Mais ' }} detalhes <i class="fas fa-chevron-down" :class="{'fa-chevron-up': exibeErroOriginal}"></i>
          </a>
          <comum-collapse>
            <div class="mt-2" v-if="exibeErroOriginal">
              <p><b>Código:</b> {{erroOriginal.codigo}}</p>
              <p><b>Mensagem:</b> {{erroOriginal.mensagem}}</p>
            </div>
          </comum-collapse>
        </div>

      </section>

      <footer class="modal-card-foot">
          <button
            v-for="(button, index)  in buttons"
            class="button"
            :class="button.css"
            @click="resolve(button.value)"
            v-bind:key="index"
          >{{button.label}}</button>
      </footer>
    </div>
  </div>
</transition>
</template>

<script>
import events from './events';
import { getEventBus } from './Notivuecation';
import comumCollapse from '@/components/comum/comum_collapse';

export default {
  components: {
    comumCollapse,
  },
  data() {
    return {
      notifications: [],
      exibeErroOriginal: false
    };
  },
  methods: {
    onShowNotification(notification) {
      this.notifications.push(notification);
    },
    onHideNotification(notification) {
      const index = this.notifications.indexOf(notification);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
    onEscapeUp(event) {
      if (event.keyCode === 27 && this.notification) {
          this.resolve();
      }
    },
  },
  mounted() {
    getEventBus().$on(events.SHOW_NOTIFICATION, this.onShowNotification);
    getEventBus().$on(events.HIDE_NOTIFICATION, this.onHideNotification);

    window.addEventListener('keyup', this.onEscapeUp);
  },
  computed: {
    resolve() {
      return this.notification ? this.notification.resolve : null;
    },
    buttons() {
      return this.notification ? this.notification.buttons : null;
    },
    title() {
      return this.notification ? this.notification.title : null;
    },
    message() {
      return this.notification ? this.notification.message : null;
    },
    erroOriginal() {
      return this.notification ? this.notification.erroOriginal : null;
    },
    tipo() {
      return this.notification ? this.notification.tipo : null;
    },
    notification() {
      return this.notifications[0];
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscapeUp);
    getEventBus().$off(events.SHOW_NOTIFICATION, this.onShowNotification);
    getEventBus().$off(events.HIDE_NOTIFICATION, this.onHideNotification);
  },
};

</script>


<style lang="scss" scope>
  $warning: #f0994e;
  $danger: #f1464e;
  $success:#48c774;
  $info: #3298dc;
  $grey: #697176;
  $white: #fff;
  $altura-icone: 42px;
  $tamanho-font-icone: 20px;

  .fa-dot-circle {
    color: $warning;
  }

  .notivuecation-overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }

  .notivuecation-content {
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 3px;
  }

  .notivuecation-content h3 {
    margin: 0;
  }

  .notivuecation-buttons {
    text-align: right;
  }

#koopon-comum-alerta-generico {
  z-index: 10000;

  .modal-content {
    border-left: 5px solid #333;

    &.warning {
      border-color: $warning;
      a {
        color: $warning;
      }
      .modal-card-title {
        color: $warning;
      }
      .modal-card-foot button.is-primary {
        background: $warning;
        border-color: $warning;
        &:hover {
          background: darken($warning, 7%);
        }
      }
    }
    &.info {
      border-color: $info;
      a {
        color: $info;
      }
      .modal-card-title {
        color: $info;
      }
      .modal-card-foot button.is-primary {
        background: $info;
        border-color: $info;
        &:hover {
          background: darken($info, 7%);
        }
      }
    }
    &.danger {
      border-color: $danger;
      a {
        color: $danger;
      }
      .modal-card-title {
        color: $danger;
      }
      .modal-card-foot button.is-primary {
        background: $danger;
        border-color: $danger;
        &:hover {
          background: darken($danger, 7%);
        }
      }
    }
    &.success {
      border-color: $success;
      a {
        color: $success;
      }
      .modal-card-title {
        color: $success;
      }
      .modal-card-foot button.is-primary {
        background: $success;
        border-color: $success;
        &:hover {
          background: darken($success, 7%);
        }
      }
    }

    .koopon-alerta-generico-mais-mensagens {
      margin-top: 20px;
      &:hover {
        color: $grey;
      }
    }

    .modal-card-head {
      // border-bottom: none;

      .koopon-alerta-generico-icone {
        width: $altura-icone;
        height: $altura-icone;
        line-height: $altura-icone;
        text-align: center;
        border-radius: $altura-icone / 2;
        font-size: $tamanho-font-icone;

        &.warning {
          color: $warning;
          background-color: rgba($warning, 0.3);
        }
        &.info {
          color: $info;
          background-color: rgba($info, 0.3);
        }
        &.danger {
          color: $danger;
          background-color: rgba($danger, 0.3);
        }
        &.success {
          color: $success;
          background-color: rgba($success, 0.3);
        }
      }
      .modal-card-title {
        font-weight: 700;
        line-height: $altura-icone;
        margin-left: 10px;
      }
      .close {
        color: lighten($grey, 15%);
        margin-top: 0 !important;
      }
    }

    .modal-card-foot {
      border-top: none;
    }
  }
}
</style>
