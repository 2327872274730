import { mfCarregandoEvento } from "../constants"
import { mfPessoaEvento } from "../constants"

export default {
    exibeCarregando(mensagem?:string) {
        document.dispatchEvent( 
            new CustomEvent(mfCarregandoEvento.EXIBIR_CARREGANDO, {
              detail: {
                mensagem: mensagem
              }
            })
        );
    },

    escondeCarregando() {
        document.dispatchEvent( 
            new CustomEvent(mfCarregandoEvento.ESCONDER_CARREGANDO)
        );
    },

    exibeAlerta(obj:{msg?:string, tipo?:string}) {
        document.dispatchEvent( 
            new CustomEvent(mfPessoaEvento.modal.EXIBE_MODAL_ALERTA, {
              detail: {
                tipo: obj.tipo,
                msg: obj.msg
              }
            })
        );
    },
}