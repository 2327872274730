export default class ErrosBackend {

    static parseErrors(error:ErroRetorno) {
        let msg = "";
        if(error.response && error.response.data && error.response.data.erros) {
            let iterations = error.response.data.erros.length;
            for (const erro of error.response.data.erros) {
                msg += erro.mensagem;
                if (--iterations)
                    msg += "<br>"
            }
            return msg;
        }
        return 'Ocorreu um problema!';
    }
}

interface ErroRetorno {
    response: {
        data: {
            erros: [
                { mensagem:string}
            ]
        }
    }
}