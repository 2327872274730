import axios from 'axios'
import { Pessoa } from '../models/pessoa_model'
import { KOOPON_PESSOA_BASE_API, KOOPON_VALIDACAO_ENTIDADE } from '../constants'

export default {
    async criar(obj:Partial<Pessoa>) {
        if (typeof obj != 'object' || Array.isArray(obj)) {
          return Promise.reject('Parâmetro deve ser um objeto.');
        }

        const { data } = await axios.post(KOOPON_PESSOA_BASE_API + 'pessoas', obj);
        return new Pessoa(data);
    },

    async atualizar(obj:Partial<Pessoa>, id:number) {
        const { data } = await axios.put(KOOPON_PESSOA_BASE_API + 'pessoas/' + id, obj);
        return new Pessoa(data);
    },

    async verificarMovimentos(idPessoa: number) {
        const resposta = await axios.get(`${KOOPON_VALIDACAO_ENTIDADE}validacao/pessoas/${idPessoa}/modulos-com-movimentos-relacionados`)
        const { data } = resposta

        return data
    },


    async obterTiposPessoa(pagina:number, tiposPessoa?:string[]) {
        const params = tiposPessoa && tiposPessoa.length ? { propriedade: new Array(tiposPessoa.length).join('descricao,') + 'descricao', valor: tiposPessoa.toString() } : undefined;

        try {
            const { data } = await axios.get(`${KOOPON_PESSOA_BASE_API}tipos-pessoa`, {
                params: {
                    pagina: pagina,
                    itensPorPagina: 100,
                    ...params
                }
            });

            return data ? data.conteudo : null;
        }
        catch(error) {
            return null;
        }
    },

    verificaAlteracaoTipo(idPessoa:number, codigoTipoPessoa:number, tiposPessoa?:number[]) {
        const params = tiposPessoa && tiposPessoa.length ? { tiposPessoa: tiposPessoa.toString() } : undefined;

        const VALIDACAO_TIPO_API = `${KOOPON_VALIDACAO_ENTIDADE}validacao/pessoas/${idPessoa}/alteracao-tipo`;

        return axios.get(`${VALIDACAO_TIPO_API}/${codigoTipoPessoa}`, {params});
    },

    async obterPorId(id:number) {
        const { data } = await axios.get(KOOPON_PESSOA_BASE_API + 'pessoas/' + id);
        return new Pessoa(data);
    },

    async obterPorCpfCnpj(cpfCnpj:string) {
        if (!cpfCnpj) {
            return Promise.reject('CPF/CNPJ deve ser informado para fazer a busca.');
        }
        const { data } = await axios.get(`${KOOPON_PESSOA_BASE_API}pessoas/cpfCnpj/` + cpfCnpj);
        return data;
    }
}