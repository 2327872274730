<script lang="ts">
    import { find } from 'lodash';
    import { Endereco } from '@/models/endereco_model';
    import customEvents from '@/services/custom_events';
    import enderecoStore from '@/stores/endereco_store';
    import ErrosBackend from '../../libs/comum/helpers/comum_error_parse';
    import ListaEnderecos from '@/components/endereco/ListaEnderecos.vue';
    import cepService from '@/libs/comum/services/comum_service_cep_service';
    import { ESTADOS as estados, BACEN_BRASIL, TIPOS_ENDERECO, TIPOS_ENDERECO_ARRAY } from '@/constants';
    import municipiosService from '@/libs/comum/services/comum_service_municipio_service';

    export default {
        name: 'EnderecoComponent',
        components: {
            ListaEnderecos
        },
        data: () => ({
            estados: estados,
            cidades: [] as string[],
            cidade: {
                codigoMunicipio: '',
                municipio: ''
            },
            indexEnderecoSelecionado: -1,
            carregandoEndereco: false,
            tipoEnderecoCadastrado: false,
            temTodosTiposEndereco: false,
            editMode: false,
            showMore: false
        }),
        computed: {
            endereco() {
                return enderecoStore.state.endereco;
            },
            enderecos() {
                return enderecoStore.state.enderecos;
            },
            tiposEndereco() {
                return TIPOS_ENDERECO_ARRAY;
            }
        },
        methods: {
            scrollToList(goTop = false, htmlElement?: HTMLElement) {
                const element = htmlElement ?? this.$el;
                this.$nextTick(() => {
                    if (element) element.scrollTop = goTop ? 0 : element.scrollHeight;
                });
            },
            setComportamentoFormulario(value: boolean) {
                this.showMore = value;
                this.editMode = value;
            },
            limparEnderecoPreenchido() {
                this.indexEnderecoSelecionado = -1;
                this.tipoEnderecoCadastrado = false;

                this.limpaCidade();
                enderecoStore.dispatch('setEndereco', new Endereco());
                enderecoStore.dispatch('setManutencaoIniciada', this.showMore);
                enderecoStore.dispatch('setEnderecoInvalido', false);
            },
            adicionarNovoEndereco() {
                this.showMore = !this.showMore;
                enderecoStore.dispatch('setManutencaoIniciada', this.showMore);

                if (!this.enderecos.some((endereco) => endereco.principal === true)) {
                    this.endereco.identificacao = TIPOS_ENDERECO.PRINCIPAL;
                }

                const element = document.getElementById('koopon-modal-pessoa-especifica');

                if (element) {
                    this.scrollToList(false, element);
                }
            },
            editarEndereco(endereco: Endereco, index: number) {
                this.indexEnderecoSelecionado = index;

                this.setComportamentoFormulario(true);
                this.scrollToList(true);
                enderecoStore.dispatch('setEndereco', endereco);
                enderecoStore.dispatch('setManutencaoIniciada', this.showMore);
            },
            validarEndereco() {
                if (this.validarTipoEnderecoCadastrado()) {
                    this.tipoEnderecoCadastrado = true;
                    return false;
                }

                if (!this.endereco.validarEndereco()) {
                    return false;
                }

                return true;
            },
            gravarManutencaoEndereco() {
                const isEnderecoValido = this.validarEndereco();
                enderecoStore.dispatch('setEnderecoInvalido', !isEnderecoValido);

                if (!isEnderecoValido) return;

                if (this.editMode)
                    enderecoStore.dispatch('atualizarEndereco', this.indexEnderecoSelecionado);
                else
                    this.enderecos.push(new Endereco(this.endereco));

                enderecoStore.dispatch('ordenar');
                this.fecharManutencaoEndereco();
                this.scrollToList();
            },
            excluirEndereco(endereco: Endereco, index: number) {
                enderecoStore.dispatch('setEndereco', endereco);
                enderecoStore.dispatch('excluirEndereco', index);

                this.setComportamentoFormulario(false);
                this.limparEnderecoPreenchido();
            },
            fecharManutencaoEndereco() {
                enderecoStore.dispatch('setEnderecoInvalido', false);
                this.setComportamentoFormulario(false);
                this.limparEnderecoPreenchido();
            },
            validarTipoEnderecoCadastrado() {
                if (this.editMode) {
                    const tipoEnderecoOrigem = this.enderecos[this.indexEnderecoSelecionado]?.identificacao;

                    if (this.endereco.identificacao?.codigo === tipoEnderecoOrigem.codigo) return false;
                }

                return this.enderecos.some(
                    (endereco) => endereco.identificacao.codigo === this.endereco.identificacao?.codigo
                );
            },
            verificarTodosTiposEnderecoCadastrados() {
                this.temTodosTiposEndereco = this.tiposEndereco.length === this.enderecos.length;
            },
            onSelectTipoEndereco() {
                this.tipoEnderecoCadastrado = false;
                this.endereco.removerPendencia('identificacao')
            },
            obterPorCep(onChange = false) {
                let cep = this.endereco.cep;

                if (!cep) {
                    this.carregandoEndereco = false;
                    return;
                }

                cep = cep.replace(/\D/g, '');

                this.endereco.limparPendencias();

                if ((!!onChange && cep.length === 8) || (!onChange)) {
                    this.carregandoEndereco = true;
                    customEvents.exibeCarregando();

                    cepService.obterPorCep(cep)
                    .then((info) => {
                        this.endereco.estende(info);
                        this.obterMunicipiosDoEstado(info.estado.sigla, info.codigoMunicipioIBGE);
                    })
                    .catch((error) => {
                        this.endereco.cep = cep;
                        customEvents.exibeAlerta({msg:ErrosBackend.parseErrors(error) || "Houve um problema ao buscar o CEP"});
                        customEvents.escondeCarregando();
                    })
                    .finally(() => {
                        this.carregandoEndereco = false;
                        customEvents.escondeCarregando();
                    });
                }
            },

            obterMunicipiosDoEstado(uf:string, codigo?:string) {
                if (uf === 'EX') {
                    this.cidades = [];
                    return;
                }
                municipiosService.obterMunicipios(uf)
                .then((municipios) => {
                    this.cidades = [...municipios];
                })
                .catch(() => {
                    this.cidades = [];
                })
                .finally(() => {
                    if (codigo) {
                        this.cidade = find(this.cidades, {codigoMunicipio: codigo});
                    }
                });
            },

            atualizaCodigoPaisBacen(uf:string) {
                if (uf === 'EX') {
                    this.endereco.codigoPaisBacen = undefined;
                    return;
                }
                this.endereco.codigoPaisBacen = BACEN_BRASIL;
            },

            enderecoUfAtualizado() {
                this.limpaCidade();
                this.obterMunicipiosDoEstado(this.endereco.estado.sigla);
                this.atualizaCodigoPaisBacen(this.endereco.estado.sigla);

                if (this.endereco.isEstadoExterior()) {
                    this.endereco.removerPendencia('bairro');
                    this.endereco.removerPendencia('cidade');
                }
            },

            enderecoMunicipioAtualizado() {
                this.endereco.cidade = this.cidade ? this.cidade.municipio : undefined;
                this.endereco.codigoMunicipioIBGE = this.cidade ? this.cidade.codigoMunicipio : undefined;
            },

            limpaCidade() {
                this.cidade = {codigoMunicipio: '', municipio: ''};
                this.endereco.cidade = undefined;
                this.endereco.codigoMunicipioIBGE = undefined;
            }
        },
        watch: {
            endereco: function() {
                this.obterMunicipiosDoEstado(this.endereco.estado.sigla, this.endereco.codigoMunicipioIBGE);
            },
            enderecos: function() {
                this.verificarTodosTiposEnderecoCadastrados();
            }
        },
        beforeDestroy() {
            enderecoStore.dispatch('limparEnderecoStore');
        },
    };
</script>

<template>
    <div
        ref="enderecosContainer"
        id="mf-erpforme-pessoa-formulario-endereco-container"
        class="endereco-container-scroll">
        <div
            v-if="!temTodosTiposEndereco"
            class="is-flex is-flex-direction-row is-align-items-center py-2">
            <button
                id="erp4me-mf-pessoa-botao-novo-endereco"
                class="button"
                :disabled="showMore"
                @click="adicionarNovoEndereco"
                >
                <i class="fas fa-plus mr-2"></i>
                Adicionar endereço
            </button>
            <div class="w-full ml-1">
                <hr/>
            </div>
        </div>
        <Transition v-if="showMore">
            <div class="mt-3">
                <div class="col-xs-12">
                    <div class="columns">
                        <div class="column is-3">
                            <label>Tipo de endereço</label>
                            <div>
                                <vue-multiselect
                                    v-model="endereco.identificacao"
                                    track-by="codigo"
                                    label="descricao"
                                    placeholder=""
                                    :class="{'is-danger': tipoEnderecoCadastrado || endereco.propPossuiPendencia('identificacao')}"
                                    :options="tiposEndereco"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    @select="onSelectTipoEndereco">
                                    <span slot="noResult">Nenhum resultado encontrado</span>
                                </vue-multiselect>
                            </div>
                            <span
                                class="has-text-danger is-size-7"
                                v-show="endereco.propPossuiPendencia('identificacao')"
                                v-html="endereco.obterMensagemPendencia('identificacao')">
                            </span>
                            <span
                                class="has-text-danger is-size-7"
                                v-show="tipoEnderecoCadastrado">
                                Tipo de endereço já cadastrado
                            </span>
                        </div>
                        <div class="column is-3">
                            <label>CEP</label>
                            <input
                                type="text"
                                id="mf-pessoa-erpforme-especifica-aba-endereco-input-cep"
                                class="input koopon-comum-formulario-endereco-input-cep"
                                maxlength="9"
                                v-mask="'#####-###'"
                                v-model="endereco.cep"
                                @change="obterPorCep(true)"/>
                        </div>
                        <div class="column is-6">
                            <label>Logradouro</label>
                            <input type="text"
                                id="mf-pessoa-erpforme-especifica-aba-endereco-input-logradouro"
                                class="input koopon-comum-formulario-endereco-input-logradouro"
                                :class="{'is-danger': endereco.propPossuiPendencia('logradouro')}"
                                @input="endereco.removerPendenciaLogradouro()"
                                :disabled="carregandoEndereco"
                                v-model.trim="endereco.logradouro"
                                maxlength="60"
                            />
                            <span
                                class="has-text-danger is-size-7"
                                v-show="endereco.propPossuiPendencia('logradouro')"
                                v-html="endereco.obterMensagemPendencia('logradouro')">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="columns">
                        <div class="column is-3">
                            <label>Número</label>
                            <input type="text"
                                id="mf-pessoa-erpforme-especifica-aba-endereco-input-numero"
                                class="input koopon-comum-formulario-endereco-input-numero"
                                :disabled="carregandoEndereco"
                                v-model.trim="endereco.numero"
                                maxlength="60"
                            />
                        </div>
                        <div class="column is-9">
                            <label>Complemento</label>
                            <input type="text"
                                id="mf-pessoa-erpforme-especifica-aba-endereco-input-complemento"
                                class="input koopon-comum-formulario-endereco-input-complemento"
                                :disabled="carregandoEndereco"
                                v-model.trim="endereco.complemento"
                                maxlength="60"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="columns">
                        <div class="column is-6">
                            <label>Bairro</label>
                            <input type="text"
                                id="mf-pessoa-erpforme-especifica-aba-endereco-input-bairro"
                                class="input koopon-comum-formulario-endereco-input-bairro"
                                :class="{'is-danger': endereco.propPossuiPendencia('bairro')}"
                                @change="endereco.removerPendenciaBairro()"
                                :disabled="carregandoEndereco"
                                v-model.trim="endereco.bairro"
                                maxlength="60"/>
                            <span
                                class="has-text-danger is-size-7"
                                v-show="endereco.propPossuiPendencia('bairro')"
                                v-html="endereco.obterMensagemPendencia('bairro')">
                            </span>
                        </div>
                        <div class="column is-2">
                            <label>UF</label>
                            <vue-multiselect
                                id="mf-pessoa-erpforme-especifica-select-veiculo-transportadora-ufs"
                                class="koopon-comum-formulario-endereco-select-ufs"
                                v-model="endereco.estado"
                                :class="{'is-danger': endereco.propPossuiPendencia('estado')}"
                                :options="estados"
                                track-by="sigla"
                                label="sigla"
                                :max-height="128"
                                :close-on-select="true"
                                :show-labels="false"
                                :allow-empty="false"
                                placeholder=""
                                :disabled="carregandoEndereco"
                                @select="endereco.removerPendencia('estado')"
                                @input="enderecoUfAtualizado();">
                                <span slot="noResult">Nenhum resultado encontrado</span>
                            </vue-multiselect>
                            <span
                                class="has-text-danger is-size-7"
                                v-show="endereco.propPossuiPendencia('estado')"
                                v-html="endereco.obterMensagemPendencia('estado')">
                            </span>
                        </div>
                        <div class="column is-4">
                            <label>Cidade</label>
                            <vue-multiselect
                                id="mf-pessoa-erpforme-especifica-aba-endereco-select-municipio"
                                class="koopon-comum-formulario-endereco-select-municipios"
                                :class="{'is-danger': endereco.propPossuiPendencia('cidade')}"
                                v-model="cidade"
                                :options="cidades"
                                track-by="codigoMunicipio"
                                label="municipio"
                                :max-height="128"
                                :close-on-select="true"
                                :show-labels="false"
                                :allow-empty="false"
                                placeholder=""
                                :disabled="carregandoEndereco"
                                @select="endereco.removerPendencia('cidade')"
                                @input="enderecoMunicipioAtualizado">
                                <span slot="noResult">Nenhum resultado encontrado</span>
                                <span slot="noOptions">Selecione um estado</span>
                            </vue-multiselect>
                            <span
                                class="has-text-danger is-size-7"
                                v-show="endereco.propPossuiPendencia('cidade')"
                                v-html="endereco.obterMensagemPendencia('cidade')">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="is-flex is-flex-row is-justify-content-flex-end my-4">
                    <button
                        id="erp4me-mf-pessoa-botao-adicionar-endereco"
                        class="button is-default mr-2"
                        @click="gravarManutencaoEndereco">
                        {{ editMode ? 'Atualizar' : 'Adicionar' }}
                    </button>
                    <button
                        class="button is-default"
                        @click="fecharManutencaoEndereco">
                        Cancelar
                    </button>
                </div>
                <hr />
            </div>
        </Transition>
        <div v-show="!editMode">
            <ListaEnderecos
                @editarEndereco="editarEndereco"
                @excluirEndereco="excluirEndereco"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    hr {
        height: 1px;
        background-color: #efefef;
    }
    .endereco-container-scroll {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 450px;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 14px;
        }
        &::-webkit-scrollbar-thumb:vertical {
            border: 4px solid #fff;
            border-radius: 10px;
            background-color: #dbdbdb;
            background-clip: padding-box;
        }
    }
</style>

