import axios, { AxiosError } from 'axios'
import { CORE_BASE_API } from '@/constants'
import passaporteUsuarioLogadoManager from '@/libs/passaporte/managers/passaporte_usuario_logado_manager';

export default {
    async obterPorCodigo(chave: number) {
      const usuarioLogado = passaporteUsuarioLogadoManager.retorna();

      try {
        const { status } = await axios.get(
          `${CORE_BASE_API}tour/usuario/${usuarioLogado.idUsuario}/codigo/${chave}`
        );

        return status === 200;
      }
      catch(error) {
        const axiosError = (error as AxiosError);

        if (axiosError.response && axiosError.response.status === 404) return false;

        throw error;
      }
    },

    async setarTourFinalizado(chave: number): Promise<number[]> {
      const vistos = [chave];

      const { data } = await axios.put(`${CORE_BASE_API}tour`, {vistos});

      return !!data && !!data.vistos ? data.vistos : [];
    }
}