import { ENUM_TIPOS_PESSOA as tiposPessoa } from '../constants';
import { tipoPessoaType } from '@/types'
import { TipoPessoa } from './tipo_pessoa_model';
import {isValid, isPast, parse} from 'date-fns'
import { Endereco } from './endereco_model';
import { Comentario } from './comentario_model';
import { Anexo } from './anexo_model';
import { Email } from './email_model';
import { Telefone } from './telefone_model';
import { Veiculo } from './veiculo_model';
import { Base } from '../libs/comum/models/base_model';
import ValidadorCpfCnpj from '../libs/comum/helpers/comum_validador_cpf_cnpj';

export class Pessoa extends Base{
    idPessoa:undefined|number = undefined;
    idPessoaApi = '';
    nome:string|undefined = undefined;
    nomeFantasia:string|undefined = undefined;
    dataNascimento:string|undefined = undefined;
    cpfCnpj = '';
    tiposPessoa:Array<TipoPessoa> = [];
    inscricaoEstadual:string|undefined = undefined;
    inscricaoMunicipal:string|undefined = undefined;
    tipoContribuinteInscricaoEstadual:number|undefined = undefined;
    suframa:string|undefined = undefined;
    categoriaEntradaPadrao:string|undefined = undefined;
    categoriaSaidaPadrao:string|undefined = undefined;
    centroCustoPadrao:string|undefined = undefined;
    endereco:Endereco = new Endereco();
    enderecos: Endereco[] = [];
    telefones:Telefone[] = [];
    emails:Email[] = [];
    site:string|undefined = undefined;
    anotacoes:Comentario[] = [];
    anexos:Anexo[] = [];
    veiculosTransportadora:Veiculo[] = [];
    status = 1;
    temPendenciasContato = false;
    temPendenciasEndereco = false;
    temPendenciasVeiculos = false;

    constructor(p?: Partial<Pessoa>) {
        super();
        Object.assign(this, p);
        this._parseEmail();
        this._parseEndereco();
        this._parseEnderecos();
        this._parseTelefones();
        this._parseComentarios();
        this._parseAnexos();
        this._parseVeiculos();
        this._parseNomeFantasia();
    }

    estende(p:Partial<Pessoa>) {
      Object.assign(this, p);
      this._parseEmail();
      this._parseEndereco();
      this._parseEnderecos();
      this._parseTelefones();
      this._parseComentarios();
      this._parseAnexos();
      this._parseVeiculos();
      this._parseNomeFantasia();
    }

    isNew() {
      return !this.idPessoa;
    }

    contribuinteIcms() {
      return this.tipoContribuinteInscricaoEstadual === 1;
    }

    naoContribuinteIcms() {
      return this.tipoContribuinteInscricaoEstadual === 9;
    }

    /**
     * @memberof alt.koopon.pessoaApp.kooponPessoaPessoaModel
     * @function atualizaCadastroReceita
     * @description verifica as informações nas Flags e preenche os Cadastro RECEITA de acordo
     * @static
    */
     atualizaSelectCadastroReceita() {
      const tipos = [tiposPessoa.CLIENTE, tiposPessoa.SOCIO, tiposPessoa.PERSONALIZADO] as TipoPessoa[];

      return !this.possuiTiposPessoa(tipos);
    }

    /**
     * @memberof alt.koopon.pessoaApp.kooponPessoaPessoaModel
     * @function atualizaCadastroDespesa
     * @description verifica as informações nas Flags e preenche os Cadastro DESPESA de acordo
     * @static
    */
     atualizaSelectCadastroDespesa() {
      const tipos = [tiposPessoa.FUNCIONARIO, tiposPessoa.FORNECEDOR, tiposPessoa.SOCIO, tiposPessoa.PERSONALIZADO] as TipoPessoa[];

      return !this.possuiTiposPessoa(tipos);
    }

    /**
     * @memberof alt.koopon.pessoaApp.kooponPessoaPessoaModel
     * @function atualizaCentroDeCusto
     * @description verifica as informações nas Flags e preenche o Centro de Custo de acordo
     * @static
    */
     atualizaSelectCentroDeCusto() {
      const tipos = [tiposPessoa.CLIENTE, tiposPessoa.FUNCIONARIO, tiposPessoa.FORNECEDOR, tiposPessoa.SOCIO, tiposPessoa.PERSONALIZADO] as TipoPessoa[];

      return !this.possuiTiposPessoa(tipos);
    }

    formataSuframa() {
      if (!this.suframa || !this.suframa.length) {
        return;
      }

      this.suframa = this.suframa.replace(/\D/g, '');
    }

    cpfCnpjValido() {
      if (!this.cpfCnpj) {
        return true;
      }

      return ValidadorCpfCnpj.cpfValido(this.cpfCnpj) || ValidadorCpfCnpj.cnpjValido(this.cpfCnpj);
    }

    isFisica() {
      return !!ValidadorCpfCnpj.cpfValido(this.cpfCnpj);
    }

    isJuridica() {
      return !!ValidadorCpfCnpj.cnpjValido(this.cpfCnpj);
    }

    isManipulavel() {
      return this.idPessoa !== -1;
    }

    isConsumidorFinalDefault() {
      if (this.idPessoa === -1) {
        return true;
      }

      return false;
    }

    temTelefonePrincipal() {
      for (const telefone of this.telefones) {
        if (telefone.principal) {
          return true
        }
      }
    }

    /**
     * @memberof alt.koopon.pessoaApp.kooponPessoaPessoaModel
     * @function validar
     * @description Retorna validação detalhada do modelo.
     * @returns {boolean}
     * @inner
     */
    validar(cpfCnpjJaExiste: boolean): boolean {
      this.limparPendencias();

      let valido = this.validarNomePessoa();

      if (!this._isTipoOk()) {
        this.adicionarPendencia({
          prop: 'listaTpPessoa',
          mensagem: 'Informe o <b>Tipo de Pessoa</b>.'
        });

        valido = false;
      }

      if (!this._isInscricaoEstadualValida()) {
        this.adicionarPendencia({
          prop: 'inscricaoEstadual',
          mensagem: 'A <b>IE</b> deve ter mais de 1 dígito.'
        });

        valido = false;
      }

      if (!this._isSuframaValido()) {
        this.adicionarPendencia({
          prop: 'suframa',
          mensagem: 'O <b>Suframa</b> deve ter 8 ou 9 dígitos.'
        });

        valido = false;
      }

      if (!this.validarCpfCnpj(cpfCnpjJaExiste)) {
        valido = this.validarCpfCnpj(cpfCnpjJaExiste);
      }

      if (!this.validarVeiculos()) {
        this.temPendenciasVeiculos = true;
        valido = this.validarVeiculos();
      }

      if (!this.validarContato()) {
        this.temPendenciasContato = true;
        valido = this.validarContato();
      }

      if (!this.validarDataNascimento()) {
        valido = this.validarDataNascimento();
      }

      return valido;
    }

    validarNomePessoa() {
      this.removerPendencia('nome');

      let valido = true;

      if (!this.nome) {
        this.adicionarPendencia({
          prop: 'nome',
          mensagem: 'Informe o <b>Nome</b>.'
        });

        valido = false;
      }
  

      if (!this._isNomeValido()) {
        this.adicionarPendencia({
          prop: 'nome',
          mensagem: 'O <b>Nome</b> deve ter mais de 1 caracter.'
        });

        valido = false;
      }

      if (this.nome && this.nome?.length > 60) {
        this.adicionarPendencia({
          prop: 'nome',
          mensagem: 'O <b>Nome</b> deve ter no máximo 60 caracteres.'
        });

        valido = false;
      }
      return valido;
    }

    validarCpfCnpj(cpfCnpjJaExiste: boolean) {
      this.removerPendencia('cpfCnpj');
      let valido = true;
      if (!this.cpfCnpjValido()) {
        this.adicionarPendencia({
          prop: 'cpfCnpj',
          mensagem: 'Informe um <b>CPF ou CNPJ</b> válido.'
        });

        valido = false;
      }
      if (cpfCnpjJaExiste) {
        this.adicionarPendencia({
          prop: 'cpfCnpj',
          mensagem: 'CPF/CNPJ já cadastrado.'
        });
        valido = false;
      }
      return valido;
    }

    validarContato() {
      let valido = true;

      this.emails.forEach(email => {
          if (email.verificaPendenciaEmailInvalido()) {
              valido = false;
          }
          if (email.emailRepetido) {
              valido = false
          }
      })

      this.telefones.forEach(telefone => {
        if (telefone.verificaPendenciaNumeroInvalido()) {
          valido = false;
        }
        if (telefone.verificaPendenciaTipoInvalido()) {
          valido = false;
        }
        if (telefone.numeroRepetido) {
          valido = false;
        }
      });
      return valido;
    }

    validarDataNascimento () {
      let valido = true;
      if (!this._isDataNascimentoValida()) {
        this.adicionarPendencia({
          prop: 'dataNascimento',
          mensagem: 'Informe uma <b>Data de Nascimento</b> válida.'
        });

        valido = false;
      }
      return valido;
    }

    validarVeiculos() {
      let valido = true;
      this.veiculosTransportadora.forEach(veiculo => {
        if (veiculo.verificaPendenciaPlacaInvalida()) {
          valido = false;
        }
        if (veiculo.placaRepetida) {
          valido = false;
        }
      });
      return valido;
    }

    removePendenciasEndereco(inputComPendencia:boolean) {
      if (inputComPendencia) {
        this.temPendenciasEndereco = false;
      }
    }

    removePendenciasContato(inputComPendencia:boolean) {
      if (inputComPendencia) {
        this.temPendenciasContato = false;
      }
    }

    removePendenciasVeiculos(inputComPendencia:boolean) {
      if (inputComPendencia) {
        this.temPendenciasVeiculos = false;
      }
    }

    _isTipoOk() {
      return !!this.tiposPessoa.length;
    }

    _isNomeValido() {
      return this.nome && this.nome.length > 1;
    }

    _isInscricaoEstadualValida() {
      return !this.inscricaoEstadual || this.inscricaoEstadual.length > 1;
    }

    _isSuframaValido() {
      return !this.suframa || (this.suframa.length === 8 || this.suframa.length === 9);
    }

    _isDataNascimentoValida() {
      // Necessário formatar pois o back não espera uma string vazia
      if (this.dataNascimento === "") {
        this.dataNascimento = undefined;
      }
      return !this.dataNascimento || (isValid(new Date(this.dataNascimento)) && isPast(new Date(this.dataNascimento)));
    }

    existeEnderecoPrincipal() {
      return this.enderecos.some((endereco) => endereco.principal === true);
    }

    private _parseEmail() {
      if (Array.isArray(this.emails) && this.emails.length) {
          this.emails = this.emails.map((email) => new Email(email))
      }
    }

    private _parseTelefones() {
      this.telefones = this.telefones.map((t) => {
        return new Telefone(t);
      });
    }

    private _parseComentarios() {
      this.anotacoes = this.anotacoes.map((a) => {
        return new Comentario(a);
      });
    }

    private _parseAnexos() {
      this.anexos = this.anexos.map((a) => {
        if (a.data) {
           a.data = parse(`${a.data}`, 'dd/MM/yyyy HH:mm:ss', new Date());
        }

        return new Anexo(a);
      });
    }

    private _parseVeiculos() {
      this.veiculosTransportadora = this.veiculosTransportadora.map((v) => {
        return new Veiculo(v);
      });
    }

    private _parseEndereco() {
      this.endereco = new Endereco(this.endereco);
    }

    private _parseEnderecos() {
      this.enderecos = this.enderecos.map((endereco) => {
        return new Endereco(endereco);
      });
    }

    private _parseNomeFantasia() {
      if (this.isJuridica()) {
        this.nomeFantasia = this.nomeFantasia || this.nome;
      }
    }

    _existeTipoPessoaArrayPorCodigo(array:Array<TipoPessoa>, value:number) {
      return array.some((item:TipoPessoa) => item.codigoTipoPessoa === value);
    }

    possuiTiposPessoa(tipos:TipoPessoa | Array<TipoPessoa>) {
      if (!tipos) {
        return false;
      }

      if (Array.isArray(tipos)) {
        return this.tiposPessoa.some((t) => this._existeTipoPessoaArrayPorCodigo(tipos, t.codigoTipoPessoa));
      }

      return this.tiposPessoa.some((t) => t.codigoTipoPessoa === tipos.codigoTipoPessoa);
    }
}
