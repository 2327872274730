import axios from 'axios'
import _ from 'lodash';

import { KOOPON_CORE_API_MUNICIPIOS as API, ESTADOS as estados } from "../../../constants"

export default {
    obterMunicipios(uf:string) {
        if (!uf) {
            return new Promise(function(resolve, reject) {
                reject(new TypeError('UF deve ser informado para fazer a busca.'));
            });
        }
        const estado = _.find(estados, {sigla: uf});
        if (!estado) {
            return new Promise(function(resolve, reject) {
                reject(new TypeError('UF inválida.'));
            });
        }

        return axios.get(API.replace('{UF}', estado.sigla))
        .then(({data}) => {
            return data;
        });
    }
}