import { PendenciaFormulario } from './pendencia_formulario_model';

export class Base {
    public propsPendenciasLista:PendenciaFormulario[] = [];
  
    constructor(e?: Partial<Base>) {
        Object.assign(this, e);
    }

    limparPendencias () {
      this.propsPendenciasLista = [];
    }

    adicionarPendencia (pendencia:Partial<PendenciaFormulario>) { 
      const _p = new PendenciaFormulario(pendencia);

      this.propsPendenciasLista.push(_p);
    }

    removerPendencia (prop:string) {
      if (this.propPossuiPendencia(prop)) {
        const indice = this.propsPendenciasLista.map((item) => { return item.prop; }).indexOf(prop);
        this.propsPendenciasLista.splice(indice, 1);
      }
    }

    propPossuiPendencia (prop:string) {
      return this.propsPendenciasLista.some((item) => {
        return item.prop === prop;
      });
    }

    propsContagemPendencias (propsLista:string[]) {
      if (!!propsLista && propsLista.length) {
        return this.propsPendenciasLista.filter((item) => {
          return propsLista.some((prop) => {
            return prop === item.prop;
          });
        }).length;
      }
      
      if (!!propsLista && propsLista.length === 0) {
        return 0;
      } 

      return this.propsPendenciasLista.length;
    }

    obterMensagemPendencia (prop:string) {
      const _p = this.propsPendenciasLista.find((item) => {
        return item.prop === prop;
      });

      if (!_p) {
        return '';
      }

      return _p.mensagem;
    }

    obterListaPendenciasAgrupadas () {
      return this.propsPendenciasLista.filter((item) => {
        return item.tipo === PendenciaFormulario.tipos().AGRUPADA;
      });
    }

    existemPendenciasDoTipo (tipo:string) {
      return this.propsPendenciasLista.some((item) => {
        return (item.tipo === tipo);
      });
    }

    possuiPendencias() {
      return !!this.propsPendenciasLista.length;
    }
}
