
import { Base } from '../libs/comum/models/base_model';

import { ENUM_TIPOS_TELEFONE as tiposTelefones } from '@/constants';

export class Telefone extends Base {
    public idTelefoneApi = '';
    public numero = '';
    public tipo = 0;
    public contato = '';
    public principal = false;
    public numeroRepetido:boolean|undefined = undefined;

    constructor(t?: Partial<Telefone>) {
        super();
        Object.assign(this, t);
    }

    temTipoValido() {
        for (const tipo of tiposTelefones) {
            if (tipo.enum === this.tipo) {
                return true;
            }
        }
        return false;
    }

    temNumeroValido() {
        const _numero = this.numero ? this.numero.replace(/\D/g, '') : '';

        return _numero.length >= 10 && _numero.length <= 11;
    }

    verificaPendenciaTipoInvalido() {
        this.removerPendencia('tipo')
        if (!this.temTipoValido()) {
          this.adicionarPendencia({
            prop: 'tipo',
            mensagem: 'Informe um <b>Tipo</b> válido.'
          });
          return true;
        }
        return false;
    }

    verificaPendenciaNumeroInvalido() {
        this.removerPendencia('numero')
        if (!this.temNumeroValido()) {
          this.adicionarPendencia({
            prop: 'numero',
            mensagem: 'Informe um <b>Número</b> válido.'
          });
          return true;
        }
        return false;
    }

    verificaRepeticaoNumero(telefones:Telefone[]) {
      const _numeros = Object.create(null);
      for (let i = 0; i < telefones.length; ++i) {
          const numero = telefones[i].numero;
          if (numero in _numeros) {
              telefones[i].numeroRepetido = true;
          }
          else {
              telefones[i].numeroRepetido = undefined;
          }
          if (numero) {
              _numeros[numero] = true;
          }
      }
    }

}