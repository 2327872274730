import Vue from 'vue';
import Vuex from 'vuex';
import { cloneDeep } from 'lodash';
import { Endereco } from '@/models/endereco_model';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    endereco: new Endereco(),
    enderecos: [] as Endereco[],
    removidos: [] as  Endereco[],
    manutencaoIniciada: false,
    enderecoInvalido: false,
  },
  mutations: {
    setEndereco(state, endereco: Endereco) {
      state.endereco = cloneDeep(endereco);
    },
    setEnderecos(state, enderecos: Endereco[]) {
      state.enderecos = enderecos;
    },
    setManutencaoIniciada(state, manutencaoIniciada: boolean) {
      state.manutencaoIniciada = manutencaoIniciada;
    },
    setEnderecoInvalido(state, enderecoInvalido: boolean) {
      state.enderecoInvalido = enderecoInvalido;
    },     
    adicionarEndereco(state, endereco: Endereco) {
      state.enderecos.push(endereco);
    },
    atualizarEndereco(state, index: number) {
      let indexEndereco = index;

      if (state.endereco.idEnderecoApi) {
        const id = state.endereco.idEnderecoApi;
        indexEndereco = state.enderecos.findIndex((item) => item.idEnderecoApi === id);
      }

      state.endereco.parseEnderecoPrincipal();
      Vue.set(state.enderecos, indexEndereco, cloneDeep(state.endereco));
    },
    excluirEndereco(state, index: number) {
      let indexEndereco = index;

      if (state.endereco.idEnderecoApi) {
        const id = state.endereco.idEnderecoApi;
        indexEndereco = state.enderecos.findIndex((item) => item.idEnderecoApi === id);
      }

      state.removidos.push(cloneDeep(state.enderecos[indexEndereco]));
      state.enderecos.splice(indexEndereco, 1);
    },
    ordenar(state) {
      state.enderecos.sort((a,b) => a.identificacao.codigo - b.identificacao.codigo);
    },
    limparRemovidos(state) {
      state.removidos = new Array<Endereco>();
    },
    limparEnderecoStore(state) {
      state.endereco = new Endereco();
      state.enderecos = new Array<Endereco>();
      state.removidos = new Array<Endereco>();
      state.manutencaoIniciada = false,
      state.enderecoInvalido = false
    },
  },
  actions: {
    setEndereco({commit}, endereco: Endereco) {
      commit('setEndereco', endereco);
    },
    setEnderecos({commit}, enderecos: Endereco[]) {
      commit('setEnderecos', enderecos);
    },
    setManutencaoIniciada({commit}, manutencaoIniciada: boolean) {
      commit('setManutencaoIniciada', manutencaoIniciada);
    },
    setEnderecoInvalido({commit}, enderecoInvalido: boolean) {
      commit('setEnderecoInvalido', enderecoInvalido);
    },
    adicionarEndereco({commit}, endereco: Endereco) {
      commit('adicionarEndereco', endereco);
    },
    atualizarEndereco({commit}, index: number) {
      commit('atualizarEndereco', index);
    },
    excluirEndereco({commit}, index: number) {
      commit('excluirEndereco', index);
    },
    ordenar({commit}) {
      commit('ordenar');
    },
    limparRemovidos({commit}) {
      commit('limparRemovidos');
    },
    limparEnderecoStore({commit}) {
      commit('limparEnderecoStore');
    },
  }
});