import axios from 'axios'
import { TipoPessoa } from '../models/tipo_pessoa_model'
import { KOOPON_PESSOA_BASE_API } from '../constants'

export default {
    async criar(obj:Partial<TipoPessoa>) {        
        if (typeof obj != 'object' || Array.isArray(obj)) {
          return Promise.reject('Parâmetro deve ser um objeto.');
        }

        const { data } = await axios.post(KOOPON_PESSOA_BASE_API + 'tipos-pessoa', obj);        
        return new TipoPessoa(data);
    },

    async atualizar(obj:Partial<TipoPessoa>, id:number) {
        const { data } = await axios.put(KOOPON_PESSOA_BASE_API + 'tipos-pessoa/' + id, obj);
        return new TipoPessoa(data);
    },
    async buscarTipoPessoaPorId(id:number) {
        const { data } = await axios.get(KOOPON_PESSOA_BASE_API + 'tipos-pessoa/' + id);
        return new TipoPessoa(data);
    }
}