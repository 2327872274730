var render, staticRenderFns
import script from "./comum_collapse.vue?vue&type=script&lang=js"
export * from "./comum_collapse.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./comum_collapse.vue?vue&type=style&index=0&id=406b6670&prod&scoped=true&lang=css")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./comum_collapse.vue?vue&type=style&index=1&id=406b6670&prod&lang=css")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "406b6670",
  null
  ,true
)

export default component.exports