import axios from 'axios'

import { KOOPON_CORE_API_CEP as API } from "../../../constants"

export default {
    obterPorCep(cep:string) {
        if (!cep) {
            return new Promise(function(resolve, reject) {
                reject(new TypeError('Cep deve ser informado para fazer a busca.'));
            });
        }

        return axios.get(API + cep)
                .then(({data}) => {
                    return data;
                });
    }
}