
<script lang="ts">
import { Endereco } from '@/models/endereco_model';

export default {
  name: 'ItemListaEnderecos',
  props: {
    index: {
      type: Number,
      required: true
    },
    endereco: {
      type: Endereco,
      required: true
    },
    startExpanded: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showMore: false,
  }),
  computed: {
    logradouro(): string {
      const numero = this.endereco?.numero ? `nº ${this.endereco?.numero}` : '';
      const logradouro = this.endereco?.logradouro ?? '';
      const separador = numero && logradouro ? ', ' : '';

      return logradouro + separador + numero;
    }
  },
  methods: {
    editarEndereco(endereco: Endereco, index: number) {
      this.showMore = true;
      this.$emit('editarEndereco', endereco, index);
    },
    excluirEndereco(endereco: Endereco, index: number) {
      this.$emit('excluirEndereco', endereco, index);
    },
    showInformacoesEndereco() {
      this.$alert({
          tipo: 'info',
          message: `O endereço cadastrado como <strong>Principal</strong> 
            será o utilizado em seus movimentos no sistema. Ex:
            NF-e, NFC-e, NFS-e.`
      });
    }
  },
  mounted() {
    this.showMore = this.startExpanded;
  }
}
</script>

<template>
  <div class="item-list p-3 col-xs-12 mt-2">
    <div class="columns is-variable is-2 item-list-header">
      <div class="column is-10">
        <div class="is-flex is-align-items-center">
          <div v-if="endereco?.principal" class="mr-2" @click="showInformacoesEndereco">
            <i class="fa fa-info-circle is-clickable has-text-info is-size-4 is-size-3-mobile"></i>
          </div>
          <div 
            class="row is-flex is-justify-content-flex-start item-list-header-container is-size-5 is-size-4-mobile"
            @click="showMore = !showMore">
            <span class="item-list-title mr-1">{{ endereco.identificacao?.descricao }}</span>
            <div class="is-flex is-align-items-center">
              <i class="is-size-4 fa fa-fw fa-angle-down" :class="[showMore ? 'expanded' : '']"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2 item-list-actions is-flex is-align-items-flex-center is-justify-content-flex-end">
        <div class="row">
          <i 
            class="fas fa-edit item-list-action-icon mr-3"
            title="Editar"
            @click="editarEndereco(endereco, index)"></i>
          <i
            class="fas fa-trash-alt item-list-action-icon"
            title="Excluir"
            @click="excluirEndereco(endereco, index)"></i>            
        </div>
      </div>
    </div>
    <div
      class="item-list-content"
      v-if="showMore">
      <div class="columns is-variable is-2 mt-1">
        <div class="column is-6">
          <label class="item-list-column-label">Logradouro</label>
          <div class="row item-list-column-text">
            <span :title="logradouro">{{ logradouro }}</span>
          </div>
        </div>
        <div class="column is-6">
          <label class="item-list-column-label">Bairro</label>
          <div class="row item-list-column-text">
            <span :title="endereco.bairro">{{ endereco.bairro }}</span>
          </div>
        </div>
      </div>
      <div class="columns is-variable is-2">
        <div class="column is-6">
          <label class="item-list-column-label">Cidade</label>
          <div class="row item-list-column-text">
            <span :title="endereco.cidade">{{ endereco?.cidade }}</span>
          </div>
        </div>
        <div class="column is-3">
          <label class="item-list-column-label">CEP</label>
          <div class="row">
            <span>{{ endereco.getCepFormatado() }}</span>
          </div>
        </div>
        <div class="column is-3">
          <label class="item-list-column-label">UF</label>
          <div class="row">
            <span>{{ endereco.estado.sigla }}</span>
          </div>
        </div>
      </div>
      <div class="columns is-variable is-2 mt-3">
        <div class="column is-12 item-list-actions-mobile">
          <div class="row is-flex is-justify-content-flex-end">
            <button 
              class="button mr-2"
              @click="editarEndereco(endereco, index)">
              Editar
            </button>
            <button 
              class="button"
              @click="excluirEndereco(endereco, index)">
              Excluir
            </button>           
          </div>
        </div>
      </div>   
    </div>
  </div>
</template>