
<script lang="ts">
export default {
  name: 'UndoToastComponent',
  data: () => ({
    message: '',
    showToast: false,
    toastTimeout: 0
  }),
  methods: {
    show(message?: string) {
      this.showToast = true;
      this.message = message ?? 'Ação realizada';

      this.toastTimeout = setTimeout(() => {
        this.close();
      }, 5000)
    },
    undo() {
      this.$emit('onUndoClick');
      this.close();
    },
    close() {
      this.showToast = false;
      clearTimeout(this.toastTimeout);
      this.$emit('onToastClose');
    }
  },
  expose: ['show']
}
</script>

<template>
  <Transition name="undo-toast-fade">
    <div v-if="showToast" class="undo-toast">
      <div class="undo-toast-content">
        <span class="undo-toast-message">{{ message }}</span>
        <a class="undo-toast-action" @click="undo">Desfazer</a>
        <button class="undo-toast-close" @click="close">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
  @import './styles/undo-toast.scss'
</style>