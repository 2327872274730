import { format } from 'date-fns'

export class Anexo {
    public arquivo:{file: File}
    public idAnexo = '';
    public nome?:string;
    public tamanho?:number;
    public data:Date;
    public selecionadoEm:Date;
    public urlDownload?:string;

    constructor(a?:Partial<Anexo>) {
        Object.assign(this, a);
        this.arquivo = {file: new File([], '', {type: "text/plain"})}
        this.selecionadoEm = new Date();
        if (!this.data) {
            this.data = new Date();
        }
    }

    public estendeArquivo(instanciaArquivo:File) {
      this.arquivo = {file: instanciaArquivo || {}};
      this.nome = this.arquivo.file.name;
      this.tamanho = this.arquivo.file.size;
    }

    public dataFormatada() {
      return format(new Date(this.data), "dd/MM/yyyy HH:mm:ss");
    }

    getTamanhoArquivo() {
      return this.arquivo ? this.arquivo.file.size : 0;
    }

    getExtensaoArquivo() {
      const _ext = [];
      const _nomeArquivo = this.arquivo ? this.arquivo.file.name : "";

      for (let i = _nomeArquivo.length; i > 0; i--) {
        if (_nomeArquivo[i] === ".") {
          break;
        }

        _ext.unshift(_nomeArquivo[i]);
      }

      return (_ext.join('') || "").toLowerCase();
    }
}
