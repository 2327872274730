import { Base } from "../libs/comum/models/base_model";

export class TipoPessoa extends Base {
  codigoTipoPessoa: number;
  idTipoPessoa?: number;
  descricao: string;
  status?: number;
  permiteLogin?: boolean;
  mostrarHintDescricao?: boolean; 

  constructor(g: Partial<TipoPessoa>) {
    super();
    this.status = 1;
    this.permiteLogin = false;
    this.mostrarHintDescricao = false;
    Object.assign(this, g);
  }

  estende(obj: Partial<TipoPessoa>) {
    Object.assign(this, obj);
  }

  isNew() {
    return !this.idTipoPessoa;
  }

  isValid() {
    return !!this.descricao;
  }

  validar() {
    let valido = true;

    this.limparPendencias();

    if (!this.descricao) {
      this.adicionarPendencia({
        prop: "descricao",
        mensagem: "Informe a <b>Descrição</b>. Máximo de 50 caracteres.",
      });
      valido = false;
    }
    return valido;
  }
}
