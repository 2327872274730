import { render, staticRenderFns } from "./EnderecoComponent.vue?vue&type=template&id=2bfb7e0b&scoped=true"
import script from "./EnderecoComponent.vue?vue&type=script&lang=ts"
export * from "./EnderecoComponent.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./EnderecoComponent.vue?vue&type=style&index=0&id=2bfb7e0b&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2bfb7e0b",
  null
  ,true
)

export default component.exports