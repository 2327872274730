<template>
    <div id="mf-erpforme-pessoa-cometarios-container">
        <textarea class="textarea"
            id="koopon-pessoa-especifica-textarea-comentario"
            placeholder="Adicionar novo comentário sobre esta pessoa"
            maxlength="255" rows="3"
            v-model="comentario.texto"></textarea>
        <span class="help is-danger" v-show="exibeHintComentario">Apague o campo ou adicione o comentário</span>
        <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
            <div class="">
                <small class="has-text-grey">{{255 - (comentario.texto.length || 0)}} caracteres restantes</small>
            </div>
            
            <div class="">
                <button type="button"
                id="koopon-pessoa-especifica-button-adicionar-comentario"
                @click="adicionarComentario(comentario);"
                :disabled="!comentario.isValid()"
                class="button is-default mt-2">Adicionar Comentário</button>
            </div>
        </div>
            
        <div class="listagem-comentarios-container">
            <div class="comentarios-container" v-for="(cmt, index) in comentarios" :key="index" v-show="comentarios.length">
                <div class="campo-texto-comentario">
                    <p class="comentario">{{cmt.texto}}</p>
                    <p class="has-text-grey">
                        <span>{{cmt.nomeUsuarioPassaporte}}</span>
                        em
                        <span>{{cmt.dataFormatada()}}</span>
                    </p>
                </div>
                <span class="icone-excluir-comentario" @click="removerComentario(index)" title="Remover comentário">
                    <i class="fa fa-times"></i><span class="sr-only">Excluir</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { Comentario } from '@/models/comentario_model';
import { PassaporteUsuarioLogado } from '@/libs/passaporte/models/passaporte_usuario_logado_model';

export default {
    name: 'kooponComumComentarios',
    props: {
        anotacoes: {
            type: Array as PropType<Comentario[]>,
            required: true
        },
        usuarioAutenticado: {
            type: PassaporteUsuarioLogado,
            required: true
        }, 
        exibeHintComentario: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        comentario: new Comentario(),
        comentarios: [] as Comentario[]
    }),

    mounted() {
        this.comentarios = [...this.anotacoes]
    },

    methods: {
        adicionarComentario(novoComentario:Comentario) {
            novoComentario.nomeUsuarioPassaporte = this.usuarioAutenticado.nomeUsuario;
            this.comentarios.unshift(novoComentario);

            this.comentario = new Comentario();
        },

        removerComentario(index:number) {
            this.comentarios.forEach((c, i) => {
                if (i === index) {
                    return this.comentarios.splice(i, 1);
                }
            });
        }
    },
    watch: {
        'comentario.texto'() {
            this.$emit('atualizaCampoComentario', this.comentario.texto)
        },
        comentarios() {
            this.$emit('update', this.comentarios)
        }
    }
};
</script>

<style lang="scss">
$grey: #697176;
$grey-1: #e3e3e8;
.comentarios-container {
  border: 1px solid $grey-1;
  padding: 5px;
  margin-top: 10px;
  position: relative
}

.informacao-sobre-o-comentario {
  margin-bottom: 0
}

.comentario {
  word-break: break-word
}

.icone-excluir-comentario {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 16px;
  color: lighten($grey, 15%);
  cursor: pointer;
}
.icone-excluir-comentario:hover {
  color: $grey;
}

.campo-texto-comentario{
  padding-right:20px;
  padding-left:5px;
  padding-top:5px;
}
</style>

