import axios from 'axios'

import { KOOPON_CORE_API_CNPJ as API } from "../../../constants"

export default {
    obterPorCnpj(cnpj:string) {
        if (!cnpj) {
            return new Promise(function(resolve, reject) {
                reject(new TypeError('Cnpj deve ser informado para fazer a busca.'));
            });
        }
        return axios.get(API + cnpj)
            .then(({data}) => {
                return data;
            });
    }
}