class EmpresaManager {
    getEmpresaEscolhidaFromStorage() {
        if(!localStorage.emp_escolhida) {
            return [];
        }

        return JSON.parse(localStorage.emp_escolhida);

    }
    getEmpresas() {
        if(!localStorage.pass_usuario_auth) {
            return [];
        }

        return JSON.parse(localStorage.pass_usuario_auth).assinantes;
    }
}

const empresaManagerSingleton = new EmpresaManager();

Object.freeze(empresaManagerSingleton);

export default empresaManagerSingleton;