
import { Base } from "../libs/comum/models/base_model";
import { TIPOS_ENDERECO } from '@/constants';

type TipoEndereco = {
  codigo: number,
  descricao: string
}

export class Endereco extends Base{
    public idEnderecoApi?:string;
    public cep?:string;
    public logradouro?:string;
    public codigoMunicipioIBGE?:string;
    public codigoPaisBacen?:string;
    public numero?:string;
    public complemento?:string;
    public bairro?:string;
    public cidade?:string;
    public identificacao: TipoEndereco;
    public principal = false;
    public estado = {
        sigla: '',
        nome: ''
    };
  
    constructor(e?: Partial<Endereco>) {
        super();
        Object.assign(this, e);
        this.parseEnderecoPrincipal();
    }

    public estende(e:Partial<Endereco>) {
      const _idEnderecoApi = this.idEnderecoApi;
      Object.assign(this, e);
      this.idEnderecoApi = _idEnderecoApi;
      this.parseEnderecoPrincipal();
    }

    public temEstado() {
      return !!this.estado.sigla;
    }

    public preenchido() {
      const _temCep = !!this.cep;
      const _temLogradouro = !!this.logradouro;
      const _temNumero = !!this.numero;
      const _temComplemento = !!this.complemento;
      const _temBairro = !!this.bairro;
      const _temCidade = !!this.cidade;
      const _temEstado = this.temEstado();

      return _temCep || _temLogradouro || _temNumero ||
             _temComplemento || _temBairro || _temCidade ||
             _temEstado;
    }

    getCepFormatado() {
      return this.cep?.replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
    }

    parseEnderecoPrincipal() {
      this.principal = this.identificacao?.codigo === TIPOS_ENDERECO.PRINCIPAL.codigo;
    }

    validarEndereco() {
      let valido = true;

      if (!this._isLogradouroValido()) {
        valido = false;
        this.adicionarPendenciaLogradouro();
      }

      if (!this.isEstadoExterior() && !this._isBairroValido()) {
        valido = false;
        this.adicionarPendenciaBairro();
      }

      if (!this.temEstado()) {
        valido = false;
        this.adicionarPendenciaEstado(); 
      }

      if (!this.isEstadoExterior() && !this._isCidadeValida()) {
        valido = false;
        this.adicionarPendenciaCidade();
      }

      if (!this._isTipoEnderecoValido()) {
        valido = false;
        this.adicionarPendenciaTipoEndereco();
      }

      return valido;
    }

    adicionarPendenciaLogradouro() {
      this.removerPendencia('logradouro');

      this.adicionarPendencia({
        prop: 'logradouro',
        mensagem: 'O <b>Logradouro</b> deve ter mais de 1 caracter.'
      });
    }

    removerPendenciaLogradouro() {
      if (this._isLogradouroValido())
        this.removerPendencia('logradouro');
    }

    adicionarPendenciaBairro() {
      this.removerPendencia('bairro');

      this.adicionarPendencia({
        prop: 'bairro',
        mensagem: 'O <b>Bairro</b> deve ter mais de 1 caracter.'
      });
    }

    removerPendenciaBairro() {
      if (this._isBairroValido())
        this.removerPendencia('bairro');
    }

    adicionarPendenciaEstado() {
      this.removerPendencia('estado');

      this.adicionarPendencia({
        prop: 'estado',
        mensagem: 'Informe a <b>UF</b>.'
      });
    }

    adicionarPendenciaCidade() {
      this.removerPendencia('cidade');

      this.adicionarPendencia({
        prop: 'cidade',
        mensagem: 'Informe a <b>Cidade</b>.'
      });
    }

    adicionarPendenciaTipoEndereco() {
      this.removerPendencia('identificacao');

      this.adicionarPendencia({
        prop: 'identificacao',
        mensagem: 'Informe o <b>Tipo de endereço</b>.'
      }); 
    }
    
    isEstadoExterior() {
      return this.temEstado() && this.estado.sigla.toLocaleUpperCase() === 'EX';
    }
    
    _isLogradouroValido() {
      return this.logradouro && this.logradouro.length > 1;
    }

    _isBairroValido() {
      return this.bairro && this.bairro.length > 1;
    }

    _isCidadeValida() {
      return this.cidade && this.cidade.length > 1;
    }

    _isTipoEnderecoValido() {
      return !!this.identificacao?.codigo;
    }
}